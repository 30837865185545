import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

const GenderPage = ({ handleNextClick, value, setvalue }) => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();


  const handleClick = (gender) => {
    setErrorMessage('');
    setSelectedGender(gender);
    localStorage.setItem('gender', gender);
  };

  useEffect(() => {
    const genderFromStorage = localStorage.getItem('gender');
    if (genderFromStorage) {
      setSelectedGender(genderFromStorage);
      setErrorMessage('')
    }
  }, []);

  useEffect(() => {
    if (value === 4) {
      if (selectedGender === null) {
        setErrorMessage(t('Please select your gender'));
        setvalue(3)
      } else {
        setErrorMessage('');
        handleNextClick();

      }
    }
  }, [value, selectedGender, handleNextClick, setvalue, t]);

  const getLabelStyle = (gender) => {
    const style = {
      backgroundColor: selectedGender === gender ? '#99c990' : '#d3c8c8',
      fontSize: '30px',
      color: 'white',
      // width: '150%',
      justifyContent: "center",
      textAlign: 'center',
      borderRadius: '10px',
    };
    return style;
  };

  return (
    <>
      <div style={{ justifyContent: 'center' }} className='D_container gender-container'>
      
        <div  >
          <h2 style={{position:'relative',top:'2%',color:"black"}}  className="gender-title">{t('Choose your gender')}</h2>

        </div>
        {/* <div className='row' > */}
        {/* <div  style={{backgroundColor:'aqua'}}> */}
        <div style={{backgroundColor:'aqua'}} className='row' >
  
          {/* <div className="gender-option male" onClick={() => handleClick('male')}> */}
          {/* <div style={{minWidth:'260%',position:'relative',right:'130%'}}> */}
          <label className="gender-option male" id='gender-lable' style={getLabelStyle('Male')} onClick={() => handleClick('Male')}>{t('Male')}</label>
          {/* </div> */}
          {/* </div> */}

          {/* <div className="gender-option female" onClick={() => handleClick('female')}> */}
          {/* <div style={{minWidth:'170%',position:'relative',right:'70%'}}> */}
          <label className="gender-option female" id='gender-lable' style={getLabelStyle('Female')} onClick={() => handleClick('Female')}>{t('Female')}</label>
          {/* </div> */}
          {/* </div> */}

          {/* <div className="gender-option transgender" onClick={() => handleClick('transgender')}> */}
          {/* <div style={{minWidth:'100%',position:'relative',right:'20%'}}> */}
          <label className="gender-option transgender" id='gender-lable' style={getLabelStyle('Transgender')} onClick={() => handleClick('Transgender')}>{t('Transgender')}</label>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>



      {errorMessage && (
        <p id='gender-error-message' className="notification-text error error-message">{errorMessage}</p>
      )}
    </>
  );
}

export default GenderPage;
