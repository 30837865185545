import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import '../../../../css/custom.css';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
// import './calendarData.css'; // Assuming you put your CSS here

const renderDetails = (data, index) => {
   switch (data.type) {
      case 'Bloodpressure':
         return (
            <Col md={12} key={`bloodpressure-${index}`} className="data-section bloodpressure-section">
               <div>
                  <h2 className="data-title bloodpressure-title">
                     <strong>
                        Blood Pressure Data :
                     </strong>
                  </h2>
                  <Row>
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.testDate).format('DD-MM-YYYY hh:mm A');
                        return (
                           <Col key={`bloodpressure-item-${subIndex}`} md={4} className="data-col">
                              <Link to={"/BloodPressure"}>
                                 <div className="data-item bloodpressure-item">
                                    <p><strong>Systolic :</strong> {item.systolic}</p>
                                    <p><strong>Diastolic :</strong> {item.diastolic}</p>
                                    <p><strong>Pulse :</strong> {item.pulse}</p>
                                    <p><strong>Test Date :</strong> {item.testDate}</p>
                                    {/* <p className="data-label"><strong>Test Date :</strong> {formatedDateandTime}</p> */}
                                    {/* <p  ><strong>Updated Date :</strong> {item.formattedDate}</p> */}
                                 </div>
                              </Link>
                           </Col>
                        );
                     })}
                  </Row>
               </div>
            </Col>
         );
      case 'Diabetes':
         return (
            <Col md={12} key={`diabetes-${index}`} className="data-section diabetes-section">
               <div>
                  <h2 className="data-title diabetes-title"><strong>Diabetes Data :</strong></h2>
                  <Row>
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.testDate).format('DD-MM-YYYY hh:mm A');
                        return (
                           <Col key={`diabetes-item-${subIndex}`} md={4} className="data-col">
                              <Link to={"/Diabetes"}>
                                 <div className="data-item diabetes-item">
                                    <p><strong>Sugar Level :</strong> {item.sugarLevel}</p>
                                    <p><strong>Sugar Type :</strong> {item.sugarType}</p>
                                    <p><strong>Meal :</strong> {item.meal}</p>
                                    {/* <p><strong>Test Date :</strong> {formatedDateandTime}</p> */}
                                    <p><strong>Test Date :</strong> {item.testDate}</p>
                                    {/* <p><strong>Updated Date :</strong> {item.formattedDate}</p> */}
                                 </div>
                              </Link>
                           </Col>
                        );
                     })}
                  </Row>
               </div>
            </Col>
         );
      case 'Mood':
         return (
            <Col md={12} key={`mood-${index}`} className="data-section mood-section">
               <div>
                  <h2 className="data-title mood-title"><strong>Mood Data :</strong></h2>
                  <Row>
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.time, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY hh:mm A');

                        return (
                           <Col key={`mood-item-${subIndex}`} md={4} className="data-col">
                              <Link to={"/mood_monitor"}>
                                 <div className="data-item  ">
                                    <p><strong>Mood :</strong> {item.mood}</p>
                                    <p><strong>Reason :</strong> {item.reason}</p>
                                    <p className="note"><strong>Note :</strong> {item.note ? item.note : 'You haven\'t given any reason for this mood.'}</p>
                                    <p><strong>Time :</strong> {formatedDateandTime}</p>
                                    {/* <p><strong>Updated Date :</strong> {item.formattedDate}</p> */}
                                 </div>
                              </Link>
                           </Col>
                        );
                     })}
                  </Row>
               </div>
            </Col>
         );
      case 'Documents':
         return (
            <Col md={12} key={`documents-${index}`} className="data-section documents-section">
               <div>
                  <h2 className="data-title documents-title"><strong>Document Data :</strong></h2>
                  <Row>
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.testDate).format('DD-MM-YYYY hh:mm A');
                        return (
                           <Col key={`documents-item-${subIndex}`} md={4} className="data-col">
                              <Link to={"/Doctor-Prescription"}>
                                 <div className="data-item documents-item">
                                    <p><strong>Document Name :</strong> {item.documentName}</p>
                                    <p><strong>Document Type :</strong> {item.documentType}</p>
                                    <p><strong>Test Date :</strong> {formatedDateandTime}</p>
                                    {/* <p><strong>Updated Date :</strong> {item.formattedDate}</p> */}
                                 </div>
                              </Link>
                           </Col>
                        );
                     })}
                  </Row>
               </div>
            </Col>
         );
      default:
         return null;
   }
};

const CalendarData = () => {
   const location = useLocation();
   const allData = location.state?.data;
   const [clickedDate, setClickedDate] = useState(null); // State to hold the clicked date

   useEffect(() => {
      // Get the clicked date from location state and update the state with formatted date
      if (location.state?.clickedDate) {
         const formattedDate = moment(location.state.clickedDate).format('MMM-D YYYY');
         setClickedDate(formattedDate);
      }
   }, [location.state]);

   return (
      <>
         <div>
            <Link to={"/app-calender"}>
               <i style={{ color: '#007bff', fontSize: '32px' }} className="fas fa-arrow-alt-circle-left"></i>
            </Link>
            <div>
               <h1 style={{ textAlign: 'center' }}>{clickedDate}</h1>
            </div>
         </div>
         &nbsp;
         &nbsp;
         <div className='calendar-data'>
            {allData && allData.length > 0 ? (
               <Row>
                  {allData.map((item, index) => (
                     renderDetails(item, index)
                  ))}
               </Row>
            ) : (
               <p className="no-data-msg">No data available</p>
            )}
         </div>
      </>
   );
};

export default CalendarData;
