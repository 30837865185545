import React from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from 'react-i18next'


const DualLine = props => {
  const { t } = useTranslation();

  const { alldata } = props
  // Check if bloodPressureData is defined before using it
  if (!alldata || alldata.length === 0) {
    return <div style={{ marginLeft: '40%', marginBottom: '10px' }}>{t('No data available')}</div>;
  }


  // Define chart options
  const options = {
    plugins: {
      legend: false,
      tooltips: {
        intersect: false,
      },
      hover: {
        intersect: true,
      }
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 20,
          padding: 10,
        },
      },
      x: {
        ticks: {
          padding: 5,
        },
      },
    },
  };

  // Prepare chart data
  const chartData = {
    labels: alldata.map(data => data.testDate),
    datasets: [

      {

        label: "Systolic",
        data: alldata.map(data => data.systolic),
        borderColor: "red",
        borderWidth: "2",
        backgroundColor: "transparent",
        pointBackgroundColor: "rgba(235,129,83,1)",
        tension: 0.4
      },

      {
        label: "Diastolic",
        data: alldata.map(data => data.diastolic),
        borderColor: "rgba(11,42,151,1)",
        borderWidth: "2",
        backgroundColor: "transparent",
        pointBackgroundColor: "rgba(11,42,151,1)",
        tension: 0.4
      },
    ],
  };


  return (
    <div className="bpsugar-responsive-wavechart" >
      <Line data={chartData} options={options} height={450} width={800} />
    </div>
  );
}

export default DualLine;
