import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import BreadCrumbs from "../../breadcrumbs";
import { AesDecrypt } from "../../../services/crypto";
import { getDonationHistory, getReceivedHistory } from "./store";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import { Label } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TrackHistory = () => {
    const { id } = useParams();
    const [donationData, setDonationData] = useState([]);
    const [receivedData, setReceivedData] = useState([]);
    const [activeTab, setActiveTab] = useState('donation');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userId = localStorage.id; // Adjust this if userId is obtained differently

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (activeTab === 'donation') {
                    const response = await dispatch(getDonationHistory(userId));
                    if (response.payload.response === 'S') {
                        const decryptedData = AesDecrypt(response.payload.data);
                        setDonationData(Array.isArray(decryptedData) ? decryptedData : []);
                    }
                } else {
                    const response = await dispatch(getReceivedHistory(userId));
                    if (response.payload.response === 'S') {
                        const decryptedData = AesDecrypt(response.payload.data);
                        setReceivedData(Array.isArray(decryptedData) ? decryptedData : []);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [activeTab, userId, dispatch]);
   
    return (
        <div>
            <div>
                <Link to="/blood">
                    <i className="fas fa-arrow-alt-circle-left back-icon"></i>
                </Link>
            </div>
            <div className="breadcrumbs-container">
                <BreadCrumbs title={t('Your Donation History')} data={[{ title: t('Blood Request') }]} />
            </div>
            <div className="button-container-donation">
                <button
                    onClick={() => setActiveTab('donation')}
                    className={`toggle-button ${activeTab === 'donation' ? 'active' : ''}`}
                >
                    {t('Donation')}
                </button>
                <button
                    onClick={() => setActiveTab('received')}
                    className={`toggle-button ${activeTab === 'received' ? 'active' : ''}`}
                >
                    {t('Received')}
                </button>
            </div>
            <div className="notification-cards">
                {activeTab === 'donation' ? (
                    donationData.length > 0 ? (
                        donationData.map((receiver) => (
                            <Card style={{ marginTop: '40px' }} key={receiver.createdDate} className="blood-request-card">
                                
                                <CardHeader style={{ display: 'flex', maxHeight: '40px', padding: '10px' }}>
                                    <div>
                                        <p className="blood-group-blood">{receiver.bloodGroup}</p>
                                    </div>
                                    <div>
                                        <Label className="blood-request-card-date">
                                            {moment(receiver.createdDate).format('MM/DD/YYYY, hh:mm A')}
                                        </Label>
                                    </div>
                                </CardHeader>
                                <CardBody className="blood-request-card-body">
                                    <Label className="blood-request-card-title">
                                        <h4>
                                            <strong>{t('Receiver Name')} : {receiver.receiverName}</strong>
                                        </h4>
                                    </Label>
                                    <br></br>
                                    <Label className="blood-request-card-title">
                                        <h4>
                                            <strong>{t('District')} : {receiver.district}</strong>
                                        </h4>
                                    </Label>
                                    <br></br>
                                    <Label className="blood-request-card-title">
                                        <h4>
                                            <strong>{t('Hospital Name')} : {receiver.hospitalName}</strong>
                                        </h4>
                                    </Label>
                                </CardBody>
                            </Card>
                        ))
                    ) : (
                        <p style={{ textAlign: 'center', marginTop: '10%' }}>{t('No donation history available')}</p>
                    )
                ) : (
                    receivedData.length > 0 ? (
                        receivedData.map((receiver) => (
                            <Card style={{ marginTop: '20px' }} key={receiver._id} className="blood-request-card">
                                <CardHeader style={{ display: 'flex', maxHeight: '40px', padding: '10px' }}>
                                    <div>
                                        <p className="blood-group-blood">{receiver.bloodGroup}</p>
                                    </div>
                                    <div>
                                        <Label className="blood-request-card-date">
                                            {moment(receiver.createdDate).format('MM/DD/YYYY, hh:mm A')}
                                        </Label>
                                    </div>
                                </CardHeader>
                                <CardBody className="blood-request-card-body">
                                    <Label className="blood-request-card-title">
                                        <h4>
                                            <strong>{t('Donor Name')} : {receiver.donorName}</strong>
                                        </h4>
                                    </Label>
                                    <br></br>
                                    <Label className="blood-request-card-title">
                                        <h4>
                                            <strong>{t('District')} : {receiver.district}</strong>
                                        </h4>
                                    </Label>
                                    <Label className="blood-request-card-title">
                                        <h4>
                                            <strong>{t('Hospital Name')} : {receiver.hospitalName}</strong>
                                        </h4>
                                    </Label>
                                </CardBody>
                            </Card>
                        ))
                    ) : (
                        <p style={{ textAlign: 'center', marginTop: '10%' }} >{t('No received history available')}</p>
                    )
                )}
            </div>
        </div>
    );
};

export default TrackHistory;
