import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const FoodTypePage = ({ handleNextClick, value, setvalue }) => {
  const [selectedFoodType, setSelectedFoodType] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleClick = (foodType) => {
    setErrorMessage('');
    setSelectedFoodType(foodType);
    localStorage.setItem('foodType', foodType); // Use foodType directly here
  };

  useEffect(() => {
    const foodTypeFromStorage = localStorage.getItem('foodType');
    if (foodTypeFromStorage) {
      setSelectedFoodType(foodTypeFromStorage);
      setErrorMessage('');
    }
  }, []);

  useEffect(() => {
    if (value === 6) {
      if (selectedFoodType === null) {
        setErrorMessage(t('Please select your food type'));
        setvalue(5);
      } else {
        setErrorMessage('');
        handleNextClick();
      }
    }
  }, [value, selectedFoodType, handleNextClick, setvalue, t]);

  const getLabelStyle = (foodType) => ({
    backgroundColor: selectedFoodType === foodType ? '#99c990' : '#d3c8c8',
    fontSize: '30px',
    color: 'white',
    width: '150%',
    justifyContent: "center",
    textAlign: 'center',
    borderRadius: '10px',
  });

  return (
    <>
      <div  className='D_container'>
        <h2 style={{ marginTop: '20px', position: 'relative',color:"black" }} className="gender-title">
          {t('Choose your food type')}
        </h2>

        <div className="food-option vegetarian" onClick={() => handleClick('Vegetarian')}>
          <label style={getLabelStyle('Vegetarian')}>{t('Vegetarian')}</label>
        </div>

        <div className="food-option non-vegetarian" onClick={() => handleClick('Non-Vegetarian')}>
          <label style={getLabelStyle('Non-Vegetarian')}>{t('Non-Vegetarian')}</label>
        </div>

        <div className="food-option eggetarian" onClick={() => handleClick('Eggetarian')}>
          <label style={getLabelStyle('Eggetarian')}>{t('Eggetarian')}</label>
        </div>

        <div className="food-option vegan" onClick={() => handleClick('Vegan')}>
          <label style={getLabelStyle('Vegan')}>{t('Vegan')}</label>
        </div>
      </div>
      {errorMessage && (
        <p id='gender-error-message' className="notification-text error error-message">{errorMessage}</p>
      )}
    </>
  );
}

export default FoodTypePage;
