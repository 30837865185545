import CryptoJS from 'crypto-js';
const key = '41f9cbe46789db07cd34920df3a7c3c6'; // Replace with your secure key

export const AesEncrypt = (textData) => {

    const iv = CryptoJS.lib.WordArray.random(16); // Generating a random IV
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(textData), CryptoJS.enc.Hex.parse(key), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const Res = {
        data: cipherText.toString(),
        iv: iv.toString(CryptoJS.enc.Base64)
    };
    return Res;
}

export const AesDecrypt = (encryptedData) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedData.data, CryptoJS.enc.Hex.parse(key), {
        iv: CryptoJS.enc.Base64.parse(encryptedData.iv), // Decoding IV from base64
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    const decryptedJson = JSON.parse(decryptedString);
    return decryptedJson;
};
