import { AesEncrypt } from '../../../../services/crypto';
import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllData = createAsyncThunk('vaccination/getAllData', async () => {
    try {
        const response = await fetch(webservice + 'api/allVaccination', {
            method: 'POST', // Change method to GET
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const getDob = createAsyncThunk('UserType/getPassword', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/getDob', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});




export const VaccinationSlice = createSlice({
    name: 'vaccination',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getAllData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})