import React, { useState } from 'react';
import AddPeriodForm from './AddPeriodForm';
import PeriodList from './PeriodList';
import Prediction from './Prediction';

const PeriodTracker = () => {
    const [periods, setPeriods] = useState([]);
    const [prediction, setPrediction] = useState(null); // Placeholder for prediction state
    // Function to handle form submission from AddPeriodForm
    const handleSubmit = (newPeriod) => {
        // Update periods state with new period
        setPeriods([...periods, newPeriod]);

        // Reset form fields or do any other necessary operations
        // For example:
        // setStartDate(null);
        // setEndDate(null);
        // setNotes('');
        // setSymptoms('');
        // setQuestionIndex(0);
    };

    return (
        <>
            <h1 style={{ textAlign: 'center' }}>Period Tracker</h1>
            {/* <AddPeriodForm onSubmit={handleSubmit} /> */}
            {/* <PeriodList periods={periods} /> */}
            <Prediction prediction={prediction} />
        </>
    );
};

export default PeriodTracker;
