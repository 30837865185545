import React, { Fragment, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';

import EmailID from "./emailID";
import NamePage from "./username";
import Dob from "./dobPage";
import GenderPage from "./gender";
import FoodTypePage from "./foodType";
import HeightPage from "./HeightPage";
import WeightPage from "./WeightPage";
import BloodGroup from "./BloodGroupPage";
import MaritalStatus from "./maritial";
import ProfessionalPage from "./ProfessionalPage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Demography = () => {
	const { t } = useTranslation();
	const [goSteps, setGoSteps] = useState(0);
	const [value, setvalue] = useState(0);
	const handleNextClick = () => {
		const step = goSteps + 1
		setGoSteps(step);
		localStorage.setItem('goSteps', step);

	};
															
	const handleClick = (value) => {
		setvalue(value + 1)
	};
	const handleBackClick = (value) => {
		setvalue(value)
		setGoSteps(value)
		localStorage.setItem('goSteps', value);
	};

	React.useEffect(() => {
		const steps = localStorage.getItem('goSteps');
		if (steps !== null) {
			setGoSteps(parseInt(steps)); // Convert string to integer
		}
	}, []);

	// const buttonStyle = { padding: '0.25rem 0.5rem', fontSize: '0.875rem', height: '50px', width: '10%',position:'absolute',bottom:'13%',right:'13%' }

	return (
		<Fragment>


			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						{/* <div className="card-header">
							<h4 className="card-title">Form step</h4>
						</div> */}
						<div style={{ minHeight: '70vh' }} className="card-body">

							<div className="form-wizard">
								<Stepper className="nav-wizard" activeStep={goSteps} >
									<Step className="nav-link" onClick={() => handleBackClick(0)} />
									<Step className="nav-link" onClick={() => handleBackClick(1)} />
									<Step className="nav-link" onClick={() => handleBackClick(2)} />
									<Step className="nav-link" onClick={() => handleBackClick(3)} />
									<Step className="nav-link" onClick={() => handleBackClick(4)} />
									<Step className="nav-link" onClick={() => handleBackClick(5)} />
									<Step className="nav-link" onClick={() => handleBackClick(6)} />
									<Step className="nav-link" onClick={() => handleBackClick(7)} />
									<Step className="nav-link" onClick={() => handleBackClick(8)} />
									<Step className="nav-link" onClick={() => handleBackClick(9)} />
								</Stepper>
								{goSteps === 0 && (
									<>
										<EmailID handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-end">
											<button className="dmography-next-button " onClick={() => handleClick(0)}>{t("Next")}</button>
										</div>
									</>
								)}
								{goSteps === 1 && (
									<>
										<NamePage handleNextClick={handleNextClick} value={value} setvalue={setvalue} />

										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary  ms-1 "  style={buttonStyle} onClick={() => handleBackClick(0)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(0)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="dmography-next-button " onClick={() => handleClick(1)}>{t("Next")}</button>
										</div>
									</>
								)}
								{goSteps === 2 && (
									<>
										<Dob handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(1)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(1)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="dmography-next-button" onClick={() => handleClick(2)}>{t("Next")}</button>
										</div>
									</>
								)}
								{goSteps === 3 && (
									<>
										<GenderPage handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(2)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(2)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="dmography-next-button" onClick={() => handleClick(3)}>{t("Next")}</button>
										</div>
									</>
								)}

								{goSteps === 4 && (
									<>
										<MaritalStatus handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(6)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(3)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="dmography-next-button" onClick={() => handleClick(4)}>{t("Next")}</button>
										</div>
									</>
								)}

								{goSteps === 5 && (
									<>
										{/* <FoodTypePage handleNextClick={handleNextClick} value={value} setvalue={setvalue} /> */}
										<FoodTypePage handleNextClick={handleNextClick} value={value} setvalue={setvalue} />

										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(2)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(4)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="dmography-next-button" onClick={() => handleClick(5)}>{t("Next")}</button>
										</div>
									</>
								)}


								{goSteps === 6 && (
									<>
										<HeightPage handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(3)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(5)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="btn btn-primary sw-btn-next ms-1 dmography-next-button" onClick={() => handleClick(6)}>{t("Next")}</button>
										</div>
									</>
								)}
								{goSteps === 7 && (
									<>
										<WeightPage handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(4)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(6)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="dmography-next-button" onClick={() => handleClick(7)}>{t("Next")}</button>
										</div>
									</>
								)}
								{goSteps === 8 && (
									<>
										<BloodGroup handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(5)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(7)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>
											<button className="dmography-next-button" onClick={() => handleClick(8)}>{t("Next")}</button>
										</div>
									</>
								)}

								{goSteps === 9 && (
									<>
										<ProfessionalPage handleNextClick={handleNextClick} value={value} setvalue={setvalue} />
										<div className="toolbar toolbar-bottom p-2 d-flex justify-content-between">
											{/* <button className="btn btn-primary sw-btn-next ms-1" style={buttonStyle} onClick={() => handleBackClick(7)}>Previous</button> */}
											<div className='demography_backButton'>
												<Link >
													<i onClick={() => handleBackClick(8)} className="fas fa-arrow-alt-circle-left"></i>
												</Link>
											</div>


											<button className=" sw-btn-next ms-1 dmography-next-button "  onClick={() => handleClick(9)}>{t('Submit')}</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Demography;
