import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllData = createAsyncThunk('FirstAid/getAllData', async () => {
    try {
        const response = await fetch(webservice + 'api/AllFirstAid', {
            method: 'POST', // Change method to GET
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const getFirstAid = createAsyncThunk('FirstAid/getFirstAid', async (id) => {
    try {
        const response = await fetch(webservice + 'api/FirstAid', {
            method: 'POST',
            body: JSON.stringify({ id }), // Pass the entire data object directly

            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }

        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const DocumentUploadSlice = createSlice({
    name: 'DocumentUpload',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getAllData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})