import React from "react";
import { Link } from 'react-router-dom';
import BMICalculator from './BMICalculator';
import BMIuserCalculator from './userBmi';
import UpdateHeightWeight from './UpdateHeightWeight';

function BMIindex() {
  return (
    <>
      <div className='demography_backButton'>
        <Link to={"/dashboard"}>
          <i className="fas fa-arrow-alt-circle-left"></i>
        </Link>
      </div>
      <div className="bmi-container">
        <BMICalculator />
        <BMIuserCalculator />
        <UpdateHeightWeight />
      </div>
    </>
  );
}

export default BMIindex;
