import React, { useEffect, useState } from "react";
import { Modal, Nav, Tab, Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { bloodetails } from "./store/index"
import { useDispatch } from "react-redux";
import location from "../../../images/iconly/bulk/sos-direction.svg"

const Blooddetails = () => {

  const { id } = useParams();
  const dispatch = useDispatch()
  const [AllData, setImageData] = useState([]);
  useEffect(() => {
    dispatch(bloodetails(id)).then((response) => {
      setImageData(response.payload.data);
    })
  }, [id])
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Card className="card" style={{ boxShadow: "0px 0px 10px 5px rgb(144 144 144 / 56%)" }}>
            <Card.Header>
              <Col>
                <div className='icon_backButton'>
                  <Link to={"/blood"}>
                    <i className="fas fa-arrow-alt-circle-left"></i>
                  </Link>
                </div>

              </Col>
              <Col>
                <h2 className="text-black" dangerouslySetInnerHTML={{ __html: AllData.name }} />
              </Col>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-xl-3 col-lg-6  col-md-6">
                  <Tab.Container defaultActiveKey="first">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <img className="img-fluid" src={`data:image/jpeg;base64,${AllData.img}`} alt="" />
                      </Tab.Pane>

                    </Tab.Content>
                  </Tab.Container>
                </div>


                <div className="col-xl-9 col-lg-6  col-md-6 col-sm-12">
                  <div className="product-detail-content  text-black">
                    <div
                      dangerouslySetInnerHTML={{ __html: AllData.content }}
                    >
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Blooddetails;
