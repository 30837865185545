import { getFirstAid } from "./store/index";
import { useDispatch } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { Tab, Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FirstAIdDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [imageData, setImageData] = useState({});

  useEffect(() => {
    dispatch(getFirstAid(id)).then((response) => {
      setImageData(response.payload.data);
    });
  }, [id, dispatch]);



  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          right: '-100px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease'
        }}
        onClick={onClick}
      // onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1)'}
      // onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        <i className="fas fa-chevron-right" style={{ fontSize: '30px', color: '#000' }}></i>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          left: '-100px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease'
        }}
        onClick={onClick}
      // onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1)'}
      // onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        <i className="fas fa-chevron-left" style={{ fontSize: '30px', color: '#000' }}></i>
      </div>
    );
  };




  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };


  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <Card className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
            <Card.Header>
              <Col>
                <div className='icon_backButton'>
                  <Link to={"/first-aid"}>
                    <i className="fas fa-arrow-alt-circle-left"></i>
                  </Link>
                </div>
              </Col>
              <Col>
                <h2 className="text-black" style={{ marginLeft: "-10%" }}>{t(imageData.title)}</h2>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className="row">
                {/* <div className="col-xl-3 col-lg-6 col-md-6">
                  <Tab.Container defaultActiveKey="first">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        {imageData.img ? (
                          <img className="img-fluid" src={`data:image/jpeg;base64,${imageData.img}`} alt="First Aid" />
                        ) : (
                          <img className="img-fluid" src="/path/to/placeholder-image.jpg" alt="Placeholder" />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div> */}
                <div style={{ marginLeft: '12%' }} className="col-xl-9 col-lg-6 col-md-6 col-sm-12">
                  <div className="product-detail-content text-black">
                    {imageData.data ? (
                      <Slider {...settings}>
                        {imageData.data.map((item, index) => (
                          <div key={index} className="slider-item">
                            <h3>{item.title}</h3>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              {item.img ? (
                                <img style={{ maxWidth: '250px', maxHeight: '450px' }} className="img-fluid" src={`data:image/jpeg;base64,${item.img}`} alt={item.title} />
                              ) : (
                                <img className="img-fluid" src="/path/to/placeholder-image.jpg" alt="Placeholder" />
                              )}
                              <p style={{ marginLeft: '50px' }} >{item.description}</p>
                            </div>

                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <p>No details available</p>
                    )}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default FirstAIdDetails;
