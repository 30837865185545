import React, { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { testdetails } from "./store/index"
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'


const Testdetails = () => {

    const { id } = useParams();
    const dispatch = useDispatch()
    const [AllData, setImageData] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(testdetails(id)).then((response) => {
            setImageData(response.payload.data);
        })
    }, [dispatch, id])
    return (
        <>

            <div className="row">
                <div className="col-lg-12">
                    <Card className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
                        <Card.Header>
                            <Col>
                                <div className='icon_backButton'>
                                    <Link to={"/med_list"}>
                                        <i className="fas fa-arrow-alt-circle-left"></i>
                                    </Link>
                                </div>
                                {/* <Link
                                    className="tp-btn-light"
                                    variant="secondary"
                                    to="/med_list"
                                >
                                    {t('Back')}
                                </Link> */}
                            </Col>
                            <Col>
                                <div className="text-black" style={{ marginLeft: "-10%" }} dangerouslySetInnerHTML={{ __html: AllData.Testname }} />
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="col-xl-9 col-lg-6  col-md-6 col-sm-12">
                                    <div className="product-detail-content  text-black">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: AllData.desc }}
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>

        </>
    );
};

export default Testdetails;
