import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next'

import fruit from "../../../../images/iconly/bulk/fruits.svg"
import vegetabls from "../../../../images/iconly/bulk/vegetables.svg"
import seed from "../../../../images/iconly/bulk/Seeds.svg"
function SampleNextArrow(props) {

  const { onClick } = props;
  return (
    <div className="owl-nav disabled" onClick={() => onClick()}>
      <div className="owl-next">
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav disabled" onClick={() => onClick()}>
      <div className="owl-prev" style={{ zIndex: 1 }}>
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
    </div>
  );
}

const RecomendedSlider = () => {
  const { t } = useTranslation()
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <Slider {...settings}>
      <div className="items">
        <div className="card text-center" >
          <Link to="/fruits" className="text-black">

            <div className="card-body " >
              <img src={fruit} alt="" style={{ maxHeight: "100%", width: "100%", }} />

              <h5 className="fs-16 font-w500 mb-1">
                {t("Fruits")}
              </h5>
            </div>
          </Link>
        </div>

      </div>

      <div className="items">
        <div className="card text-center" >
          <Link to="/vegetables" className="text-black">
            <div className="card-body" >
              <img src={vegetabls} alt="" style={{ maxHeight: "100%", width: "100%", }} />
              <h5 className="fs-16 font-w500 mb-1">
                {t("Vegetables")}
              </h5>
            </div>
          </Link>
        </div>

      </div>

      <div className="items">
        <div className="card text-center" >
          <Link to="/nuts" className="text-black">
            <div className="card-body" >
              <img src={seed} alt="" style={{ maxHeight: "100%", width: "100%", }} />
              <h5 className="fs-16 font-w500 mb-1">
                {t("Dry Fruits")}
              </h5>
            </div>
          </Link>
        </div>

      </div>

      {/* <div className="items">
        <div className="card text-center"style={{ boxShadow: "6px 20px 23px 0px rgb(124 50 181 / 58%)" }} >
        <Link to="/app-profile" className="text-black">
          <div className="card-body" >
            <img src={fruit} alt="" />
            <h5 className="fs-16 font-w500 mb-1">
                Fruits
            </h5>
          </div>
          </Link>
        </div>
      
      </div> */}

    </Slider>
  );
};

export default RecomendedSlider;
