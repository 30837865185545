import React from 'react'
import { Fragment } from 'react'

/// Images
// import Ai from '../../images/card/smart.jpg'
// import Mood from '../../images/card/friends.jpg'
// import save from '../../images/card/security.jpg'


/// Bootstrap
import { Row, Col, Card, Carousel } from 'react-bootstrap'

/// carousel data

const carousel2 = [
  { text: 'Frist', title: "Wellness Assistant",description: "Empower your health journey with our app, your trusted wellness assistant for a better life." },
  {text: 'Second',title: "Mood Monitoring",description: "Track your mood daily for insights and self-awareness, empowering personal growth and well-being" },
  { text: 'Third',title: "Safe and Privacy",description: "Safely store and view your medical records with encrypted protection for complete privacy and security."},
]


const Slides = () => {
  return (
    <Fragment>

      <Row>
        <Col xl={6}>
          <Card>
            <Card.Body className='p-2'>
              <Carousel controls={false} indicators={false}>
                {carousel2.map((carousel, i) => (
                  <Carousel.Item key={i}>
                    {/* <img
                
                      width='50%'
                      src={carousel.img}
                      alt=" "
                    /> */}
                      <div className='text-black ' style={{fontSize:"50px"}}>{carousel.title} </div>
                      <h1 style={{color:"rgb(64 57 57)"}}>{carousel.description} </h1>
                  </Carousel.Item>
                  
                ))}
              </Carousel>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Slides
