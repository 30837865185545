import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';
export const getAllData = createAsyncThunk('bloodpressure/getAllData', async () => {
    const userId = localStorage.id; // Corrected variable name from Idd to userId
    const requestData = {
        userId: userId, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/getBloodPressure', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseDataEnc = await response.json();
        const responseData = AesDecrypt(responseDataEnc.data)
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const postBloodPressureData = createAsyncThunk('bloodpressure/postBloodPressureData', async (data) => {
    const Encparams = AesEncrypt(data)
    
    try {
        const response = await fetch(webservice + 'api/addBloodPressure', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const updateBloodPressureData = createAsyncThunk('bloodpressure/updateBloodPressureData', async ({ data }) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/updateBloodPressure', {
            method: 'POST', // Change method to POST
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ Encparams }), // Include both ID and data in the request body
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const deleteBloodPressureData = createAsyncThunk('bloodpressure/deleteBloodPressureData', async (id) => { // Modified to accept only ID
    const requestData = {
        _id: id, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/deleteBloodPressure', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            //body: JSON.parse(id)
            body: JSON.stringify({ Encparams }), // Pass only the ID in the request body
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const DocumentUploadSlice = createSlice({
    name: 'bloodpressure',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getAllData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})