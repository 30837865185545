import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Logout } from '../../../store/actions/AuthActions';
import { deleteUser } from './store';
import { useTranslation } from 'react-i18next';

function Deleteuser() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();


    const confirmdelete = () => {
        const Id = localStorage.id;
        Swal.fire({
            title: t('Confirm Action'),
            text: t('What would you like to do?'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Delete Account'),
            cancelButtonText: t('Deactivate Account'),
            reverseButtons: true,
            showCloseButton: true,
            cancelButtonColor: 'orange',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: t('Confirm Delete'),
                        text: t('This action is permanent. Are you sure you want to delete your account?'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: t('Yes, Delete'),
                        cancelButtonText: t('Cancel'),
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const key = 'Delete'
                            const data = { Id, key }
                            dispatch(deleteUser(data)).then((res) => {
                                const data = res.payload;
                                if (data.response === 'S') {
                                    localStorage.clear();
                                    window.location.href = '/logout'; // Redirect to logout page or handle logout logic accordingly
                                } else {
                                    Swal.fire({
                                        title: t('Error'),
                                        text: t('Failed to delete account'),
                                        icon: 'error'
                                    });
                                }
                            });
                        }
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire({
                        title: t('Deactivate Account'),
                        text: t('You can log in at any time'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: t('OK'),
                        cancelButtonText: t('Cancel')
                    }).then((result) => {
                        const key = 'Deactive'
                        const data = { Id, key }
                        if (result.isConfirmed) {
                            dispatch(deleteUser(data, 'deactivate')).then(() => {
                                localStorage.clear();
                                window.location.href = '/logout'; // Redirect to logout page or handle logout logic accordingly
                            });
                        }
                    });
                }
            });
    };


    return (
        <>
            <button className="dropdown-item ai-icon" onClick={confirmdelete}>
                <svg
                    viewBox="0 0 448 512"
                    id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                    className="text-black" width={18} height={18}
                    fill="none" stroke="currentColor" strokeWidth={40} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    {/* <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} /> */}
                </svg>
                <span className="ms-2 text-black">{t('DeleteAccount')} </span>
            </button>
        </>
    )
}



export default Deleteuser;
