import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Prediction = () => {
    const [lastPeriodDate, setLastPeriodDate] = useState(null);
    const [periodDuration, setPeriodDuration] = useState('');
    const [usualCycleLength, setUsualCycleLength] = useState('');
    const [predictedDates, setPredictedDates] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const calculateNextPeriodDates = () => {
        if (!lastPeriodDate || !usualCycleLength) return [];

        const cycleLength = parseInt(usualCycleLength);
        const dates = [];
        let nextDate = new Date(lastPeriodDate);

        for (let i = 0; i < 12; i++) {
            nextDate = new Date(nextDate.getTime());
            nextDate.setDate(nextDate.getDate() + cycleLength);
            dates.push(new Date(nextDate));
        }

        return dates;
    };

    useEffect(() => {
        if (isSubmitted) {
            setPredictedDates(calculateNextPeriodDates());
        }
    }, [isSubmitted, lastPeriodDate, usualCycleLength]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
    };

    return (
        <div className="prediction-container">
            <h2>Next Predicted Period</h2>
            {!isSubmitted ? (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Select the date of your last period:</label>
                        <DatePicker
                            selected={lastPeriodDate}
                            onChange={(date) => setLastPeriodDate(date)}
                            dateFormat="yyyy-MM-dd"
                            required
                            className="custom-datepicker"
                        />
                    </div>
                    <div className="form-group">
                        <label>How long did it last (in days)?</label>
                        <input
                            type="number"
                            value={periodDuration}
                            onChange={(e) => setPeriodDuration(e.target.value)}
                            required
                            className="custom-input"
                        />
                    </div>
                    <div className="form-group">
                        <label>What is your usual cycle length (in days)?</label>
                        <input
                            type="number"
                            value={usualCycleLength}
                            onChange={(e) => setUsualCycleLength(e.target.value)}
                            required
                            className="custom-input"
                        />
                    </div>
                    <Button type="submit" className="custom-button">Submit</Button>
                </form>
            ) : (
                <div>
                    <div className="calendar-container">
                        {/* <Calendar
                            onChange={() => {}} // Add any necessary functionality for calendar change
                            tileClassName={({ date, view }) => {
                                if (predictedDates.some(predictedDate => predictedDate.toDateString() === date.toDateString())) {
                                    return 'predicted-date'; // CSS class for highlighting predicted date
                                }
                                return '';
                            }}
                        /> */}
                    </div>
                    <div className="prediction-details">
                        <h3>Prediction Details</h3>
                        <ul>
                            {predictedDates.map((date, index) => (
                                <li key={index}>Predicted period {index + 1}: {date.toDateString()}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Prediction;
