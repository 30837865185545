import React, { useContext } from 'react'
/// React router dom
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { useSelector } from "react-redux";
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'
import { ThemeContext } from "../context/ThemeContext";


/// App
import AppProfile from './components/AppsMenu/AppProfile/AppProfile'
import Updateprofile from './components/AppsMenu/AppProfile/Updateprofile';
import Calendar from './components/AppsMenu/Calendar/Calendar'
import CalendarData from './components/AppsMenu/Calendar/calendarData'
 


//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';

//user detail
import Demography from './pages/userdetail/Demography';
import EmailID from './pages/userdetail/emailID';
import UserName from '../jsx/pages/userdetail/username';
import DOB from './pages/userdetail/dobPage';
import GenderPage from './pages/userdetail/gender';
import HeightPage from './pages/userdetail/HeightPage';
import WeightPage from './pages/userdetail/WeightPage';
import BloodGroupPage from './pages/userdetail/BloodGroupPage';
import FullDetailPage from './pages/userdetail/FullDetailPage';
import ProfessionalPage from './pages/userdetail/ProfessionalPage';
import MaritalStatusPage from './pages/userdetail/maritial';


/// Dashboard
import Home from "./components/Dashboard/Home";
import WorkoutStatistic from "./components/Dashboard/WorkoutStatistic";
import WorkoutPlan from "./components/Dashboard/WorkoutPlan";
import PersonalRecord from "./components/Dashboard/PersonalRecord";

//Document Upload
import DoctorPrescription from './components/DocumentUpload/DoctorPrescription/index'
// import LabReports from './components/DocumentUpload/LabReports/LabReports';
// import MedicalReport from './components/DocumentUpload/MedicalReports/MedicalReports';

// First Aid
import FirstAid from './components/FirstAid/FirstAid';
import FirstAidTips from './components/FirstAid/FirstAidTip';

// Vaccination
import Vaccination from './components/Vaccination/vaccination'


// Mood Monitor
import MoodMonitor from './components/MoodMonitor/mood'
import AddMood from './components/MoodMonitor/addmood';

import PeriodTracker from './components/periodTracker/index'

// blood presssure and blood dugre
import Diabetes from './components/Diabetes/index'
import BloodPressure from './components/Bloodpressure/index'

//MEDICALcHECKUPLIST

import Testdetails from './components/Medicalcheckup/Testdetails';
import Medicalcheckup from './components/Medicalcheckup/medicalcheckuplist';

//FOOD DETSILS
import Fruits from './components/FoodDetails/Fruit';
import Fruitdetais from './components/FoodDetails/Fruitdetails';
import Vegetables from './components/FoodDetails/Veg'
import Vegdetails from './components/FoodDetails/VegDetails';
import Nuts from './components/FoodDetails/Nuts';
import Nutdetails from './components/FoodDetails/Nutdetails';
import Slides from './pages/Slideshow';
//Chnage password
import Changepassword from './layouts/nav/Changepassword';
// import UiCarousel from './components/FoodDetails/Carousel'
//BLOOD SCREEN
import Blood from './components/Bloodgroup/Blood';
import FindDonner from './components/Bloodgroup/findDonor';
import RequestDonner from './components/Bloodgroup/requestDonner';
import AcceptedList from './components/Bloodgroup/acceptedList';
import CreatePost from './components/Bloodgroup/CreatePost';
import BloodNotification from './components/Bloodgroup/bloodNotification';
import TrackHistory from './components/Bloodgroup/trackHistory';

import Blooddetails from './components/Bloodgroup/Bloodetails';
import BMICalculator from './components/BMICalculator/index';

import Depression from './components/MoodMonitor/depression';
// import DAS from './components/MoodMonitor/dasquestion';
import Anxiety from './components/MoodMonitor/anxiety';
import Stress from './components/MoodMonitor/stress';
import DASHOME from './components/MoodMonitor/mentalhealth';

const Markup = () => {


  const allroutes = [
    //user detail
    { url: 'EmailID', component: <EmailID /> },
    { url: 'NamePage', component: <UserName /> },
    { url: 'DOB', component: <DOB /> },
    { url: 'GenderPage', component: <GenderPage /> },
    { url: 'HeightPage', component: <HeightPage /> },
    { url: 'WeightPage', component: <WeightPage /> },
    { url: 'BloodGroupPage', component: <BloodGroupPage /> },
    { url: 'FullDetailPage', component: <FullDetailPage /> },
    { url: 'ProfessionalPage', component: <ProfessionalPage /> },
    { url: 'MaritalStatusPage', component: <MaritalStatusPage /> },
    { url: 'Demography', component: <Demography /> },


    /// Dashboard
    { url: '', component: <Home /> },
    { url: 'dashboard', component: <Home /> },
    { url: "workout-statistic", component: <WorkoutStatistic /> },
    { url: "workoutplan", component: <WorkoutPlan /> },
    { url: "personal-record", component: <PersonalRecord /> },

    /// Apps
    { url: 'app-profile', component: <AppProfile /> },
    { url: 'update-profile/:id', component: <Updateprofile /> },
    { url: 'app-calender', component: <Calendar /> },
    { url: 'calenderData', component: <CalendarData /> },
    
    //First-Aid
    { url: 'first-aid', component: <FirstAid /> },
    { url: 'first-aid-tips', component: <FirstAidTips /> },
    { url: 'first-aid-tips/:id', component: <FirstAidTips /> },

    //Vaccination
    { url: 'vaccination', component: <Vaccination /> },

    //Mood Monitor
    { url: 'mood_monitor', component: <MoodMonitor /> },
    { url: 'add_mood', component: <AddMood /> },

    { url: 'Diabetes', component: <Diabetes /> },
    { url: 'BloodPressure', component: <BloodPressure /> },
    // PeriodTracker
    { url: 'PeriodTracker', component: <PeriodTracker /> },

    // BMICalculator
    { url: 'BMICalculator', component: <BMICalculator /> },

    //Document Upload
    { url: 'Doctor-Prescription', component: <DoctorPrescription /> },
    // { url: 'Lab-Reports', component: <LabReports /> },
    // { url: 'Medical-Report', component: <MedicalReport /> },
    { url: 'vaccination', component: <Vaccination /> },

    //MEDICALCHECKUPLIST
    { url: "med_list", component: <Medicalcheckup /> },
    { url: "test-details/:id", component: <Testdetails /> },

    //FoodDetails
    { url: "fruits", component: <Fruits /> },
    { url: "fruits-details/:id", component: <Fruitdetais /> },
    { url: "fruits-details", component: <Fruitdetais /> },
    { url: "vegetables", component: <Vegetables /> },
    { url: "veg-details", component: <Vegdetails /> },
    { url: "veg-details/:id", component: <Vegdetails /> },
    { url: "nuts", component: <Nuts /> },
    { url: "nut-details/:id", component: <Nutdetails /> },
    { url: 'slide-show', component: <Slides/> },

    // { url: 'ui-carousel', component: <UiCarousel/> },

    //change Password
    { url: "changepassword", component: <Changepassword /> },


    //Blood Screen
    { url: "blood", component: <Blood /> },
    { url: "finddonor", component: <FindDonner /> },
    { url: "requestDonner", component: <RequestDonner /> },
    { url: "acceptedList", component: <AcceptedList /> },
    { url: "createpost", component: <CreatePost /> },
    { url: "bloodNotification", component: <BloodNotification /> },
    { url: "trackHistory", component: <TrackHistory /> },
    
    { url: "requestDonner/:id", component: <RequestDonner /> },
    { url: "finddonor/:id", component: <FindDonner /> },
    { url: "blood-details/:id", component: <Blooddetails /> },
    // { url: 'das', component: <DAS /> },
    { url: 'depression', component: <Depression /> },
    { url: 'anxiety', component: <Anxiety/> },
    { url: 'stress', component: <Stress/> },
    { url: 'mentalhealth', component: <DASHOME/> },
  ]

  return (
    <>
      <Routes>
      
        <Route  element={<MainLayout /> } >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  )
}

function MainLayout() {
  const { sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <>
      <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}>
        <Nav />
        <div className="content-body"  style={{ minHeight: "calc(100vh - 60px)" }}>
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>

    </>
  )
};


export default Markup