import React, { useState, useEffect } from 'react';
import Gender_boy from './Demographic-images/gender_boy.svg';
import { useTranslation } from 'react-i18next';

const WeightPage = ({ handleNextClick, value, setvalue }) => {
  const [weight, setWeight] = useState('');
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const scaleImage = (newWeight) => {
    let scale = 1.1; // Default scale value
    if (newWeight >= 50 && newWeight < 100) {
      scale = 1.3;
    } else if (newWeight >= 100 && newWeight < 200) {
      scale = 1.5;
    } else if (newWeight >= 200 && newWeight < 300) {
      scale = 1.7;
    } else if (newWeight >= 300 && newWeight < 400) {
      scale = 1.9;
    } else if (newWeight >= 400) {
      scale = 2.2;
    }

    const image = document.querySelector('.weight_boy_image');
    if (image) {
      image.style.transform = `scaleX(${scale})`;
    }
  };

  const handleWeightChange = (e) => {
    setErrorMessage('');
    const newWeight = parseInt(e.target.value, 10);
    if (isNaN(newWeight)) {
      setWeight('');
      scaleImage(0);
    } else {
      setWeight(newWeight);
      scaleImage(newWeight);
    }
  };

  const handleInputWeightChange = (e) => {
    setErrorMessage('');
    const newWeight = parseInt(e.target.value, 10);
    if (isNaN(newWeight)) {
      setWeight('');
      scaleImage(0);
    } else {
      setWeight(newWeight);
      scaleImage(newWeight);
    }
  };

  useEffect(() => {
    const storedWeight = localStorage.getItem('weight');
    if (storedWeight !== null) {
      setWeight(storedWeight);
      setErrorMessage('');
      scaleImage(parseInt(storedWeight, 10));
    } else {
      setWeight('');
      setErrorMessage('');
      localStorage.setItem('weight', ''); // Save default weight to localStorage
    }
  }, []);

  const handleSubmit = () => {
    if (weight === '' || weight === '0') {
      setErrorMessage(t('Please select your weight'));
      setvalue(7);
    } else {
      localStorage.setItem('weight', weight.toString()); // Set weight to localStorage when it changes
      handleNextClick();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (value === 8) {
      handleSubmit();
    }
  }, [value]);

  return (
    <>
      <div className='D_container'>
        <div className='D_column D_img_container'>
          <img
            className="weight_boy_image"
            src={Gender_boy}
            alt="Boy"
          />
        </div>
        <div className='D_column D_contant_container demography_card'>
          <div className='demography_main_contant'>
            <div id='height_Demography_card'>
              <h2 style={{ color: "black" }}>{t('Enter your weight?')}</h2>
              <input
                type='number'
                onChange={handleInputWeightChange}
                value={weight}
              />
              <label style={{ maxWidth: '80%', position: 'relative', top: '7%' }}>
                {t("Click and drag to increase your weight using slider")}
              </label>
              <input
                type="range"
                min="0"
                max="350"
                value={weight || 0} // Show 0 when weight is an empty string
                onKeyDown={handleKeyDown}
                onChange={handleWeightChange}
              />
              <div style={{ textAlign: 'center' }}>
                {weight && <span>{t('You selected : ')}<span style={{ fontSize: '1.5rem', color: 'red' }}>{weight} kg </span></span>}
              </div>
              {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Render error message */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeightPage;
