import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllData } from "./store/index"
import { useDispatch } from "react-redux";
import "../../../css/custom.css"
import img from '../../../images/First-Aid-BG.png'
import { useTranslation } from 'react-i18next'
import BreadCrumbs from "../../breadcrumbs";
const FirstAid = () => {
  const [imageData, setImageData] = useState([]);
  const dispatch = useDispatch()
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllData()).then((response) => {
      setImageData(response.payload.data);
    })
  }, [dispatch])

  return (
    <Fragment>
      <BreadCrumbs title={t('First - Aid')} data={[{ title: t('First - Aid') }]} />
      <div className="first-aid-container" style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh', width: '100%', paddingTop: '10px' }}>
        <div >
          {/* <h3 style={{ textAlign: "center", fontWeight: "bold", color: '#000' }}>{t('First - Aid')}</h3> */}
          <br />
          <div className="row ">
            {imageData.map((item, index) => (
              <div className="mood-responsive-card" key={index}>
                {/* <div className="card card-zoom" style={{ boxShadow: "6px 20px 23px 0px rgb(124 50 181 / 58%)" }}> */}
                <div
                  className="card card-zoom"
                  style={{
                    boxShadow: " 0px 0px 10px 0px rgb(153, 201, 144)",
                  }}
                >
              
                  <Link to={`/first-aid-tips/${item._id}`} >
                    <div className="card-body pb-3 "  >
                      <div className="media mb-3">
                        <img
                          src={`data:image/jpeg;base64,${item.img}`}
                          alt=""
                          className="rounded me-3"
                          width={86}
                        />
                        <div className="media-body">
                          <h4   className="fs-20 font-w600">
                            {item.title}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FirstAid;
