import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next'


import { Link, useNavigate } from "react-router-dom";

/// Scroll

import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';

/// Image

import { getfamilyData, getdataFlag, getUserType } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import Deleteuser from "./Deleteuser";


const Setting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userID = localStorage.getItem('id')

  const [Id, setId] = useState("");
  const [Alldata, setAllHead] = useState("");
  const [userFlag, setuserFlag] = useState("");
  const [UserType, setUsertype] = useState("");

  const handleReloadTable = () => {
    dispatch(getfamilyData(Id)).then((res) => {
      const head = AesDecrypt(res.payload.data)
      setAllHead(head)
    })
  };

  useEffect(() => {
    const id = localStorage.parentid;
    setId(id);
    if (Id) {
      dispatch(getfamilyData(Id)).then((res) => {
        const head = AesDecrypt(res.payload.data)
        setAllHead(head)
      })
    }
  }, [dispatch, Id])

  const getFlag = () => {

    const Id = localStorage.id;
    dispatch(getdataFlag(Id)).then((response) => {
      const userdataFlag = response.payload
      setuserFlag(userdataFlag)
    })
    handleReloadTable()
  }

  useEffect(() => {
    const id = localStorage.getItem('id');
    if (id) {
      dispatch(getUserType(id)).then((res) => {
        const usertype = AesDecrypt(res.payload.Usertype)
        setUsertype(usertype)
      })
    }
  })


  // const navigate = useNavigate()
  // const calendarNavigation = () => {
  //   navigate('/app-calender')
  // }

  return (

    <Fragment>

      {/* <ul className="navbar-nav header-right"> */}
      <Dropdown as="li" className="nav-item dropdown" onClick={() => getFlag(Id)}>
        <Dropdown.Toggle
          variant=""
          className="nav-link i-false"
          href="#"
          role="button"
          data-toggle="dropdown"
        >
          <i className="fa-solid fa-gear text-black" style={{ fontSize: '24px' }}></i>

          {/* <i className="fa-solid fa-gears" style={{ fontSize: '24px' }}></i> */}
        </Dropdown.Toggle>
        {/* <div>
          <i className="fa-solid fa-calendar text-black" style={{ fontSize: '24px', cursor: 'pointer' }} onClick={calendarNavigation}></i>

        </div> */}

        {userFlag.userDataFlag === "A" && (
          <Dropdown.Menu align="right" className="mt-2">
            <Link to="/app-profile" className="dropdown-item ai-icon">
              <svg xmlns="http://www.w3.org/2000/svg"
                className="text-black"
                viewBox="0 0 576 512"
                width={18}
                height={18}
                fill="none"
                stroke="currentColor"
                strokeWidth={40}
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z" /></svg>

              <span className="ms-2 text-black">{t('User Profile')}</span>
            </Link>

            {UserType === "User" && (
              <Link to="/changepassword" className="dropdown-item ai-icon">
                <svg xmlns="http://www.w3.org/2000/svg"
                  id="icon-user1"
                  className="text-black"
                  viewBox="0 0 448 512"
                  width={18}
                  height={18}

                  fill="none"
                  stroke="currentColor"
                  strokeWidth={40}
                  strokeLinecap="round"
                  strokeLinejoin="round">

                  <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                </svg>
                <span className="ms-2 text-black" >{t('Change Password')}</span>
              </Link>
            )}
            <LogoutPage />
            {Alldata && Alldata.map((member, index) => (
              <div key={index}>
                {member.activeStatus === 'A' && (
                  <Deleteuser />
                )}
              </div>
            ))}

          </Dropdown.Menu>
        )}
      </Dropdown>
      {/* </ul> */}
    </Fragment>
  );
};

export default Setting;
