



import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getRequestedDonor, NotificationForPost, rejectButtonClick, removeCancelAcceptClick, updateCancelAndRemove, updateComplated, updateNoThanks } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { useDispatch } from "react-redux";
import { Button, Card, Label } from "reactstrap";
import { t } from "i18next";
import BreadCrumbs from "../../breadcrumbs";
import { CardBody } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
const userId = localStorage.id


const BloodNotification = () => {
    const { id } = useParams();
    const [notificationData, setNotificationData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(NotificationForPost(userId))
            .then((response) => {
                try {
                    const decryptedData = AesDecrypt(response.payload.data);
                    if (Array.isArray(decryptedData)) {
                        setNotificationData(decryptedData);
                    } else {
                        setNotificationData([]);
                    }
                } catch (error) {
                    console.error('Error decrypting data:', error);
                    setNotificationData([]);
                }
            })
            .catch((error) => {
                console.error('Error fetching accepted blood posts:', error);
                setNotificationData([]);
            });
    }, [dispatch]);

    const handleRemoveCancelAcceptClick = (receiver) => {
    
        dispatch(removeCancelAcceptClick(receiver)).then((response) => {
            if (response.payload.response === 'S') {
                Swal.fire({
                    title: "Complete",
                    text: "Message sent to the donor successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                });

                dispatch(NotificationForPost(userId))
                    .then((response) => {
                        try {
                            const decryptedData = AesDecrypt(response.payload.data);
                            if (Array.isArray(decryptedData)) {
                                setNotificationData(decryptedData);
                            } else {
                                setNotificationData([]);
                            }
                        } catch (error) {
                            console.error('Error decrypting data:', error);
                            setNotificationData([]);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching accepted blood posts:', error);
                        setNotificationData([]);
                    });
            }else{
                Swal.fire({
                    title: "Something went wrong",
                    text: "Please try again later",
                    icon: "error",  // Since it's a retry, "error" might be more appropriate
                    confirmButtonText: "OK"
                  });                  

            }
        });
    };


    const handleRejectlick = (receiver) => {
        dispatch(rejectButtonClick(receiver)).then((response) => {
            if (response.payload.response === 'S') {
                dispatch(NotificationForPost(userId))
                    .then((response) => {
                        try {
                            const decryptedData = AesDecrypt(response.payload.data);
                            if (Array.isArray(decryptedData)) {
                                setNotificationData(decryptedData);
                            } else {
                                setNotificationData([]);
                            }
                        } catch (error) {
                            console.error('Error decrypting data:', error);
                            setNotificationData([]);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching accepted blood posts:', error);
                        setNotificationData([]);
                    });
            }else{
                Swal.fire({
                    title: "Something went wrong",
                    text: "Please try again later",
                    icon: "error",  // Since it's a retry, "error" might be more appropriate
                    confirmButtonText: "OK"
                  });                  
            }
        });
    };

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to="/blood">
                        <i style={{ color: '#007bff', fontSize: '32px', marginBottom: '2%' }} className="fas fa-arrow-alt-circle-left"></i>
                    </Link>
                </div>
                <div>
                    <BreadCrumbs title={t('Notifications For You')} data={[{ title: t('Blood Request') }]} />
                </div>
            </div>
            <div style={{ padding: '1rem' }}>
                {Array.isArray(notificationData) && notificationData.length === 0 ? (
                    <p style={{ textAlign: 'center' }}>No notification was found...!</p>
                ) : (
                    <div className="notification-cards">
                        {notificationData.map((receiver) => (
                            <Card key={receiver.postId} className="blood-request-card">
                         {       console.log('--------------------------------------',receiver)}
                                
                                <CardBody className="blood-request-card-body">
                                    <div className="blood-request-card-header">
                                        <Label className="blood-request-card-title">
                                            <strong>{receiver.name}</strong> Needs Your Help
                                        </Label>
                                        <Label className="blood-request-card-date">
                                            {moment(receiver.createdDate).format('MM/DD/YYYY, hh:mm A')}
                                        </Label>
                                    </div>
                                    <div className="blood-request-card-body-content">
                                        <Label className="blood-request-card-mobile">
                                            <i className="fa fa-phone blood-request-card-icon"></i>
                                            <strong>Mobile Number: </strong>
                                            {receiver.status === 'R' || receiver.status === 'C' || receiver.acceptStatus === false ? (
                                                '**********'
                                            ) : (
                                                receiver.mobile
                                            )}
                                        </Label>
                                    </div>

                                    <div className="blood-request-card-footer">
                                        {receiver.acceptStatus === true ? (
                                            <>
                                                {receiver.status === 'N' || receiver.status === 'D' || receiver.requestStatus === false ? (
                                                    <>
                                                        <div className="blood-request-cancel-message">
                                                            Unfortunately, {receiver.name} has canceled their donation offer
                                                        </div>
                                                        <Button
                                                            style={{ backgroundColor: '#c3c3c3', borderColor: '#c3c3c3' }}
                                                            // className="blood-request-remove-button"
                                                            onClick={() => handleRemoveCancelAcceptClick(receiver)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button
                                                        style={{ backgroundColor: '#c3c3c3', borderColor: '#c3c3c3' }}
                                                        // className="blood-request-no-thanks-button"
                                                        onClick={() => handleRemoveCancelAcceptClick(receiver)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                )}
                                            </>

                                        ) : (
                                            
                                            <>
                                                <Button
                                                    className="blood-request-no-thanks-button"
                                                    onClick={() => handleRemoveCancelAcceptClick(receiver)}
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    style={{ backgroundColor: 'rgb(213 76 93)', borderColor: 'rgb(213 76 93)' }}

                                                    onClick={() => handleRejectlick(receiver)}
                                                >
                                                    Reject
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                )}
            </div >

        </>
    );
}

export default BloodNotification;
