import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddAnxiety } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";

const Anxiety = () => {
  const dispatch = useDispatch();
  const [anxiety, setAnxiety] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const userId = localStorage.id;
  const navigate = useNavigate();

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    setError("");
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = anxietyQuestions[currentQuestionIndex].id;
    setAnxiety((prevMood) => ({ ...prevMood, [currentQuestion]: selectedChoice }));
    setSelectedChoice(anxiety[anxietyQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setSelectedChoice(anxiety[anxietyQuestions[currentQuestionIndex - 1].id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleSaveMood = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = anxietyQuestions[currentQuestionIndex].id;
    const AnxietyData = { ...anxiety, [currentQuestion]: selectedChoice };
    const data = { userId, anxiety: AnxietyData }

    dispatch(AddAnxiety(data)).then((response) => {
      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          title: t("Anxiety Test Successful"),
          icon: "success",
        });
        navigate('/mentalhealth');
      }
    });
    setAnxiety({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };

  const anxietyQuestions = [
    { question: t("1.Feeling nervous, anxious or on edge"), id: "a1" },
    { question: t("2.Not being able to stop or control worrying"), id: "a2" },
    { question: t("3.Worrying too much about different things"), id: "a3" },
    { question: t("4.Trouble relaxing"), id: "a4" },
    { question: t("5.Being so restless that it is hard to sit still"), id: "a5" },
    { question: t("6.Becoming easily annoyed or irritable"), id: "a6" },
    { question: t("7.Feeling afraid as if something awful might happen"), id: "a7" }
  ];


  const commonChoices = [
    { label: t("Not at all"), value: "0" },
    { label: t("Several days"), value: "1" },
    { label: t("More than half the days"), value: "2" },
    { label: t("Nearly every day"), value: "3" }
  ];

  const isLastQuestion = currentQuestionIndex === anxietyQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <Fragment>
      <BreadCrumbs title={t('Anxiety')} data={[{ title: t('Anxiety') }]} />

      <span className="mentalhealth text-black">{t('Over the last 2 weeks, how often have you been bothered by the following problems?')}</span>
      <Card style={{ marginTop: "2%", boxShadow: "0px 0px 10px 5px rgb(144 144 144 / 56%)" }}>
        <CardBody>
          <Form onSubmit={isLastQuestion ? handleSaveMood : handleNextQuestion}>
            <FormGroup tag="fieldset">
              <legend className="text-black" style={{ fontSize: "30px" }}>{anxietyQuestions[currentQuestionIndex].question}</legend>
              {commonChoices.map((choice, i) => (
                <FormGroup className="text-black" check key={i}>
                  <Label check>
                    <Input
                      type="checkbox"
                      className="form-check-input mb-4"
                      value={choice.value}
                      checked={selectedChoice === choice.value}
                      onChange={() => handleChoiceSelection(choice.value)}
                    />
                    {choice.label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            {error && <Alert color="danger">{error}</Alert>}
            <div style={{ marginLeft: "75%" }}>
              {!isFirstQuestion && (
                <Button color="secondary" onClick={handlePreviousQuestion} style={{ marginRight: "10px" }}>
                  {t('Previous')}
                </Button>
              )}
              <Button color="primary" type="submit" className="small-button" >
                {isLastQuestion ? t('Save') : t('Next')}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Anxiety;
