import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { getAllData } from '../store';
import PrescriptionListing from './prescriptionListing';
import Breadcrumbs from '../../../breadcrumbs'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap';
import { Card } from 'reactstrap';
function ChartChartjs() {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [selectedValue, setSelectedValue] = useState(10);


  const handleSelectChange = (e) => {
    setSelectedValue(parseInt(e.target.value));
  };
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    dispatch(getAllData())
      .then((response) => {
        setAllData(response.payload);
      });
  }, [dispatch]);

  const handleReload = () => {
    dispatch(getAllData())
      .then((response) => {
        if (response.payload && Array.isArray(response.payload)) {
          setAllData(response.payload);
        } else {
          console.error('Invalid data format:', response.payload);
        }
      })
  }




  return (
    <>
      <Breadcrumbs title={t('Medical Records')} data={[{ title: t('Medical Records') }]} />

      <Card>
        <div >
          <Form.Select
            value={selectedValue}
            onChange={handleSelectChange}
            style={{
              cursor: 'pointer',
              maxWidth: "10%",
              marginLeft: "2%",
              marginTop: "2%",
              backgroundColor: "#f8f9fa",
              fontSize: "14px",
              border: "1px solid #ced4da",
              borderRadius: "4px",
              padding: "5px 10px"
            }}
          >
            <option value={10} style={{ backgroundColor: "#fff", color: "#000" }}>Last 10</option>
            <option value={50} style={{ backgroundColor: "#fff", color: "#000" }}>Last 50</option>
            <option value={100} style={{ backgroundColor: "#fff", color: "#000" }}>Last 100</option>
          </Form.Select>

        </div>

        {/* <PrescriptionListing alldata={allData.slice(-selectedValue)} handleReload={handleReload}/> */}
        <PrescriptionListing
          alldata={allData && allData.length > 0 ? allData.slice(-selectedValue) : []}
          handleReload={handleReload}
        />

      </Card>
    </>
  );
}

export default ChartChartjs;
