import { lazy, Suspense, useEffect } from 'react';
/// Components
import Index from './jsx/index';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import './config/i18n'

// const UserName = lazy(() => import('./jsx/pages/userdetail/username'));
// const DOB = lazy(() => import('./jsx/pages/userdetail/dobPage'));
// const GenderPage = lazy(() => import('./jsx/pages/userdetail/gender'));
// const HeightPage = lazy(() => import('./jsx/pages/userdetail/HeightPage'));
// const WeightPage = lazy(() => import('./jsx/pages/userdetail/WeightPage'));
// const BloodGroupPage = lazy(() => import('./jsx/pages/userdetail/BloodGroupPage'));
// const FullDetailPage = lazy(() => import('./jsx/pages/userdetail/FullDetailPage'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const OTPPage = lazy(() => import('./jsx/pages/OTPPage'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));
// const NamePage = lazy(() => import('./jsx/pages/userdetail/username'));
const Login = lazy(() => import('./jsx/pages/Login'));
// const Home = lazy(() =>  import('../src/jsx/components/Dashboard/Home'));

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

function App(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const currentPath = window.location.pathname;
        if (
            currentPath !== '/page-register' &&
            currentPath !== '/OTPPage' &&
            currentPath !== '/page-forgot-password' &&
            currentPath !== '/page-reset-password'
        ) {
            checkAutoLogin(dispatch, navigate);
        }
    }, [dispatch, navigate]);


    let routes = (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/page-register' element={<SignUp />} />
            <Route path='/OTPPage' element={<OTPPage />} />
            <Route path='/page-forgot-password' element={<ForgotPassword />} />
            <Route path='/page-reset-password' element={<ResetPassword />} />

        </Routes>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 
