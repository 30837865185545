import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import BreadCrumbs from "../../breadcrumbs"; // Make sure this is the correct import
import Swal from "sweetalert2";
import axios from "axios";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import webservice from "../../../services/webservice"; // Make sure this is the correct import
import { AesDecrypt, AesEncrypt } from "../../../services/crypto";
import { bloodRequestPost, completeBloodPost, deleteBloodPost, updateComplated, updateNoThanks, updateReceived, updateRemoveStatus, userPostResponce } from "./store";
import { Card } from "react-bootstrap";
import moment from "moment";

const CreatePost = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [donateModal, setDonateModal] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);

    const [address, setAddress] = useState("");
    const [hospitalName, setHospitalName] = useState("");
    const [selectedState, setSelectedState] = useState(null);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedTaluk, setSelectedTaluk] = useState(null);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [talukOptions, setTalukOptions] = useState([]);
    const [stateData, setStateData] = useState({});
    const [userbloodRequestPost, setUserBloodRequestPost] = useState({});
    const [bloodRequestPostData, setBloodRequestPostData] = useState([]);
    const [bloodRequestPostDataModel, setBloodRequestPostDataModel] = useState(false);
    const [selectedReason, setSelectedReason] = useState('');
    const [postIdToDelete, setPostIdToDelete] = useState(null);
    const userId = localStorage.id;
    const EncryptedUserId = AesEncrypt(userId)
    const handleCreatePost = async () => {
        setDonateModal(true);
    };

    const bloodGroupOptions = [
        { value: "A+", label: "A+" },
        { value: "A-", label: "A-" },
        { value: "B+", label: "B+" },
        { value: "B-", label: "B-" },
        { value: "O+", label: "O+" },
        { value: "O-", label: "O-" },
        { value: "AB+", label: "AB+" },
        { value: "AB-", label: "AB-" },
        { value: "All Type", label: "All Type" },
    ];

    const stateOptions = [
        { value: 'Andhra Pradesh', label: 'ANDHRA PRADESH' },
        { value: 'Arunachal Pradesh', label: 'ARUNACHAL PRADESH' },
        { value: 'Assam', label: 'ASSAM' },
        { value: 'Bihar', label: 'BIHAR' },
        { value: 'Chhattisgarh', label: 'CHHATTISGARH' },
        { value: 'Goa', label: 'GOA' },
        { value: 'Gujarat', label: 'GUJARAT' },
        { value: 'Haryana', label: 'HARYANA' },
        { value: 'Himachal Pradesh', label: 'HIMACHAL PRADESH' },
        { value: 'Jharkhand', label: 'JHARKHAND' },
        { value: 'Karnataka', label: 'KARNATAKA' },
        { value: 'Kerala', label: 'KERALA' },
        { value: 'Madhya Pradesh', label: 'MADHYA PRADESH' },
        { value: 'Maharashtra', label: 'MAHARASHTRA' },
        { value: 'Manipur', label: 'MANIPUR' },
        { value: 'Meghalaya', label: 'MEGHALAYA' },
        { value: 'Mizoram', label: 'MIZORAM' },
        { value: 'Nagaland', label: 'NAGALAND' },
        { value: 'Odisha', label: 'ODISHA' },
        { value: 'Punjab', label: 'PUNJAB' },
        { value: 'Rajasthan', label: 'RAJASTHAN' },
        { value: 'Sikkim', label: 'SIKKIM' },
        { value: 'Tamil Nadu', label: 'TAMIL NADU' },
        { value: 'Telangana', label: 'TELANGANA' },
        { value: 'Tripura', label: 'TRIPURA' },
        { value: 'Uttar Pradesh', label: 'UTTAR PRADESH' },
        { value: 'Uttarakhand', label: 'UTTARAKHAND' },
        { value: 'West Bengal', label: 'WEST BENGAL' },
        { value: 'Andaman and Nicobar Islands', label: 'ANDAMAN AND NICOBAR ISLANDS' },
        { value: 'Chandigarh', label: 'CHANDIGARH' },
        { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU' },
        { value: 'Lakshadweep', label: 'LAKSHADWEEP' },
        { value: 'Delhi', label: 'DELHI' },
        { value: 'Puducherry', label: 'PUDUCHERRY' },
        { value: 'Ladakh', label: 'LADAKH' },
        { value: 'Jammu and Kashmir', label: 'JAMMU AND KASHMIR' }
    ];


    const handleBloodgroupChange = async (selectedOption) => {
        setSelectedBloodGroup(selectedOption)
    }

    const handleStateChange = async (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedDistrict(null);
        setSelectedTaluk(null);
        setHospitalName(null);
        setDistrictOptions([]);
        setTalukOptions([]);
        try {
            const response = await axios.post(webservice + "api/get-districts-taluks", { state: selectedOption.value });
            const data = response.data.districtsAndTaluks;
            setStateData(data);
            const districts = Object.keys(data).map((district) => ({ value: district, label: district }));
            setDistrictOptions(districts);
        } catch (error) {
            console.error("Error fetching districts and taluks:", error);
        }
    };

    const handleDistrictChange = (selectedOption) => {
        if (!selectedOption) {
            setSelectedDistrict(null);
            setTalukOptions([]);
            return;
        }


        setSelectedDistrict(selectedOption);
        if (selectedState && selectedOption) {
            const taluks = stateData[selectedOption.value].map((taluk) => ({ value: taluk, label: taluk }));
            setTalukOptions(taluks);
            setSelectedTaluk("");
        }
    };



    useEffect(() => {
        dispatch(bloodRequestPost(userId)).then((response) => {
            if (response.payload.response === 'S') {
                const decryptedData = AesDecrypt(response.payload.data)
                setUserBloodRequestPost(decryptedData);
            } else {
                // Swal.fire({
                //     title:'post illa'
                // })
                // setUserBloodRequestPost('');

            }

        });
    }, [dispatch]);

    const handleSubmit = async () => {
        if (!selectedState || !selectedDistrict || !selectedTaluk || !selectedBloodGroup || !address || !hospitalName) {
            Swal.fire({
                title: 'Incomplete form',
                text: 'Please fill all necessary fields',
                icon: 'info',
                confirmButtonText: "OK",
            });
            return;
        }

        const postData = {
            state: selectedState.value,
            district: selectedDistrict.value,
            subDistrict: selectedTaluk.value,
            bloodGroup: selectedBloodGroup.value,
            hospitalName: hospitalName,
            address: address,
            userId: userId,
        };

        const Encparams = AesEncrypt(postData);

        try {
            const response = await axios.post(webservice + "api/createBloodPost", { Encparams: Encparams });
            setDonateModal(false);
            setSelectedState(null);
            setSelectedDistrict(null);
            setSelectedTaluk(null);
            setSelectedBloodGroup(null);
            setAddress("");
            setHospitalName("");

            if (response.data.response === "S") {
                Swal.fire({
                    title: "Registered Successfully!",
                    text: "Thank you for registering ,our donors will reach you soon..!",
                    icon: "success",
                    confirmButtonText: "OK",
                })
                    .then(() => {
                        dispatch(bloodRequestPost(userId)).then((response) => {
                            const decryptedData = AesDecrypt(response.payload.data)
                            setUserBloodRequestPost(decryptedData);
                        });
                    });

            } else {
                Swal.fire({
                    title: "Error",
                    text: "Error While create blood request post. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };




    const reasons = [
        "Got another donor",
        "Donated successfully",
        "No longer needed",
        "Other"
    ];

    const handleRadioChange = (reason) => {
        setSelectedReason(reason);
    };

    const handleDelete = (_id) => {
        setDeleteModel(true);
        setPostIdToDelete(_id);

    };

    const handleConfirmDeletePost = async () => {
        try {
            const deleteData = { postId: postIdToDelete, reason: selectedReason };
            const response = await dispatch(deleteBloodPost(deleteData));

            if (response.payload.response === 'S') {
                const bloodRequestResponse = await dispatch(bloodRequestPost(userId));
                if (bloodRequestResponse.payload.response === 'S') {
                    const decryptedData = AesDecrypt(bloodRequestResponse.payload.data);
                    setUserBloodRequestPost(decryptedData);
                    setDeleteModel(false);
                }

                setDeleteModel(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Your post has been deleted Succesfully.',
                    confirmButtonText: 'OK'
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'else Oops...',
                    text: 'Failed to delete post  ',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: ' catchOops...',
                text: 'Something went wrong! Could not delete the post.',
                confirmButtonText: 'OK'
            });
        }
    };



    const handleResponse = async (receiver) => {
        try {
            const response = await dispatch(userPostResponce(receiver));

            if (response.payload.response === 'F') {
                Swal.fire({
                    title: "Responce",
                    text: "No one has responded to your post",
                    icon: "info",
                    confirmButtonText: "OK",
                });
            } else {
                const decryptedData = AesDecrypt(response.payload.data);

                setBloodRequestPostDataModel(true);
                setBloodRequestPostData(decryptedData);
            }
        } catch (error) {
            console.error('Error handling response:', error);
            Swal.fire({
                title: "Error",
                text: "Error while handling response. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const handlePostCompleted = async (receiver) => {
        try {
            const response = await dispatch(completeBloodPost(receiver));
            if (response.payload.response === 'S') {
                Swal.fire({
                    title: "Post Completed",
                    text: "The post has been successfully completed.",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                // Filter out the completed post
                setUserBloodRequestPost((prevPosts) => prevPosts.filter(post => post.postId !== receiver.postId))
    
            } else {
                Swal.fire({
                    title: "No Responses",
                    text: "No one has responded to your post.",
                    icon: "info",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.error('Error handling response:', error);
            Swal.fire({
                title: "Error",
                text: "Error while handling response. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };
    


    const handleNoThanksClick = async (receiver) => {
        const response = await dispatch(updateNoThanks(receiver));

        if (response.payload.response === 'S') {
            Swal.fire({
                icon: 'success',
                title: 'Request Declined',
                text: 'You have successfully declined the blood donor request by clicking "No Thanks".',
                confirmButtonText: 'OK'
            });
            setBloodRequestPostData(prevData => prevData.filter(item => item.responseId !== receiver.responseId));
        }
    };



    const handleCompletedClick = async (receiver) => {
        const response = await dispatch(updateComplated(receiver));
        if (response.payload.response === 'S') {
            Swal.fire({
                icon: 'success',
                title: 'Donation Completed',
                text: 'Your blood donation request has been successfully fulfilled. Thank you for your patience and cooperation!',
                confirmButtonText: 'OK'
            });
            setBloodRequestPostData(prevData => prevData.filter(item => item.responseId !== receiver.responseId));
        }
    };


    const handleRemoveClick = async (receiver) => {
        const response = await dispatch(updateRemoveStatus(receiver));
        if (response.payload.response === 'S') {
            Swal.fire({
                icon: 'success',
                title: 'Removed!',
                text: 'The blood donor request has been removed successfully.',
                confirmButtonText: 'OK'
            });

            setBloodRequestPostData(prevData => prevData.filter(item => item.responseId !== receiver.responseId));
        }
    };



    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link to={"/blood"}>
                    <i style={{ color: '#007bff', fontSize: '32px', marginBottom: '2%' }} className="fas fa-arrow-alt-circle-left"></i>
                </Link>
                <Button onClick={handleCreatePost} style={{ position: 'relative', backgroundColor: '#99c990', borderColor: '#99c990', borderRadius: '50%', width: '4%', fontSize: "24px", right: '1%' }}>+</Button>
            </div>

            <div>
                <BreadCrumbs title={t('Create Blood Request Post')} data={[{ title: t('Blood Request') }]} />
            </div>

            <Modal isOpen={donateModal} toggle={() => setDonateModal(!donateModal)}>
                <ModalHeader toggle={() => setDonateModal(!donateModal)}>
                    {t("Your Details")}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>{t("Select Needed BloodGroup")} :</Label>
                            <ReactSelect options={bloodGroupOptions} value={selectedBloodGroup} onChange={handleBloodgroupChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("Select Your State")} :</Label>
                            <ReactSelect isDisabled={!selectedBloodGroup} options={stateOptions} value={selectedState} onChange={handleStateChange} />
                        </FormGroup>
                        <FormGroup className="mt-3">
                            <Label>{t("Select Your District")} :</Label>
                            <ReactSelect isDisabled={!selectedState} options={districtOptions} value={selectedDistrict} onChange={handleDistrictChange} />
                        </FormGroup>
                        <FormGroup className="mt-3">
                            <Label>{t("Select Your Taluk")} :</Label>
                            <ReactSelect isDisabled={!selectedDistrict} options={talukOptions} value={selectedTaluk} onChange={(selectedOption) => setSelectedTaluk(selectedOption)} />
                        </FormGroup>
                        <FormGroup className="mt-3">
                            <Label>{t("Enter Hospital Name")} :</Label>
                            <Input className="text-black" rows={3} value={hospitalName} onChange={(e) => setHospitalName(e.target.value)} />
                        </FormGroup>
                        <FormGroup className="mt-3">
                            <Label>{t("Enter Hospital address")} :</Label>
                            <Input className="text-black" type="textarea" rows={3} value={address} onChange={(e) => setAddress(e.target.value)} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={handleSubmit}>
                        {t("Create post")}
                    </Button>
                </ModalFooter>
            </Modal>


            <div>
                {Array.isArray(bloodRequestPostData) && bloodRequestPostData.length === 0 ? (
                    <p style={{ textAlign: 'center' }}> </p>
                ) : (
                    <Modal isOpen={bloodRequestPostDataModel} centered toggle={() => setBloodRequestPostDataModel(!bloodRequestPostDataModel)}   >
                        <ModalHeader toggle={() => setBloodRequestPostDataModel(!bloodRequestPostDataModel)}>
                            Post Responce
                        </ModalHeader>
                        <ModalBody>
                            {bloodRequestPostData.map((receiver) => (
                                <Card key={receiver.responseId} className="blood-request-card">
                                    <div className="blood-request-card-header">
                                        <Label className="blood-request-card-title">
                                            <strong>{receiver.name}</strong> accept your request
                                        </Label>
                                        <Label className="blood-request-card-date">
                                            {moment(receiver.modifiedDate).format('MM/DD/YYYY, hh:mm A')}
                                        </Label>
                                    </div>
                                    <div className="blood-request-card-body">
                                        <Label className="blood-request-card-mobile">
                                            <i className="fa fa-phone blood-request-card-icon"></i>
                                            <strong>Mobile Number : </strong> &nbsp;
                                            {receiver.status === 'R' || receiver.status === 'C' || receiver.acceptStatus === false ? (
                                                '**********'
                                            ) : (
                                                receiver.mobile
                                            )}
                                        </Label>
                                    </div>
                                    <div className="blood-request-card-footer">
                                        {receiver.status === 'RE' || receiver.status === 'CA' || receiver.acceptStatus === false ? (
                                            <>
                                                <div className="blood-request-cancel-message">
                                                    Unfortunately, {receiver.name} has canceled their donation offer
                                                </div>
                                                <Button
                                                    className="blood-request-remove-button"
                                                    onClick={() => handleRemoveClick(receiver)}
                                                >
                                                    Remove
                                                </Button>
                                            </>

                                        ) : (
                                            <>
                                                <Button
                                                    className="blood-request-no-thanks-button"
                                                    onClick={() => handleNoThanksClick(receiver)}
                                                >
                                                    No Thanks
                                                </Button>
                                                <Button
                                                    className="blood-request-completed-button"
                                                    onClick={() => handleCompletedClick(receiver)}
                                                >
                                                    Completed
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </Card>
                            ))}
                        </ModalBody>
                    </Modal>
                )}
            </div>

            <div>
                <Modal isOpen={deleteModel} centered toggle={() => setDeleteModel(!deleteModel)}>
                    <ModalHeader toggle={() => setDeleteModel(!deleteModel)}>
                        Why do you want to delete this post?
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                {reasons.map((reason, index) => (
                                    <div key={index} className="mb-2">
                                        <Input
                                            type="radio"
                                            id={`reason-${index}`}
                                            name="deleteReason"
                                            value={reason}
                                            onChange={() => handleRadioChange(reason)}
                                            checked={selectedReason === reason}
                                        />
                                        <Label for={`reason-${index}`} style={{ marginLeft: '10px', marginTop: '6px' }}>{reason}</Label>
                                    </div>
                                ))}
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleConfirmDeletePost} disabled={!selectedReason}>Delete</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <div>
                <div className="card-container">
                    {Array.isArray(userbloodRequestPost) && userbloodRequestPost.length === 0 ? (
                        // <p style={{ textAlign: 'center' }}></p>
                        <>
                            <h3 style={{ textAlign: 'center', marginTop: '10%', color: 'grey' }}>No blood request posts have been added yet.</h3>
                        </>

                    ) : (
                        Array.isArray(userbloodRequestPost) && userbloodRequestPost.map((receiver) => (
                            <Card key={receiver.postId} className="card-custom">

                                <div className="card-header-custom">
                                    <span className="blood-group-blood">{receiver.bloodGroup || "All Blood Types"}</span>
                                    <span title={t('Delete')}
                                        onClick={() => handleDelete(receiver.postId)}
                                        className="btn btn-danger shadow btn-xs sharp">
                                        <i className="fa fa-trash"></i>
                                    </span>
                                </div>

                                <div className="card-body-custom">
                                    <p> <strong>State :</strong> {receiver.state}</p>
                                    <p> <strong>District :</strong> {receiver.district}</p>
                                    <p> <strong>Taluk :</strong> {receiver.subDistrict}</p>
                                    <p> <strong>hospitalName :</strong> {receiver.hospitalName}</p>
                                    <p> <strong>address : </strong>{receiver.address}</p>
                                    <p> <strong>Posted on : </strong> {moment(receiver.createdDate).format('MMMM DD, YYYY  hh:mm A')}</p>
                                </div>
                                <div className="card-footer-custom">
                                    {/* Posted on: {new Date(receiver.createdDate).toLocaleDateString()} */}
                                  
                                        <Button onClick={() => handleResponse(receiver)} style={{ backgroundColor: '#99c990', borderColor: '#99c990' }}  >
                                            {t("Responce")}
                                        </Button>
                                        &nbsp;
                                        <Button onClick={() => handlePostCompleted(receiver)} style={{ backgroundColor: '#99c990', borderColor: '#99c990' }}  >
                                            {t("Complete")}
                                        </Button>
                                        &nbsp;
                                        <Link to={{ pathname: `/requestDonner/${receiver.postId}` }}>
                                            <Button style={{ backgroundColor: '#99c990', borderColor: '#99c990' }} variant="danger">
                                                {t("Request")}
                                            </Button>
                                        </Link>
                                </div>
                            </Card>
                        ))
                    )}
                </div>
            </div>


        </>
    );
};



export default CreatePost;
