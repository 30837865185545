import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';


export const getAllData = createAsyncThunk('DiabetesForm/getAllData', async () => {

    const userId = localStorage.id; // Corrected variable name from Idd to userId
    const requestData = {
        userId: userId, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));

    try {
        const response = await fetch(webservice + 'api/getBloodSugar', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ Encparams }), // Change method to GET
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseDataEnc = await response.json();
        const responseData = AesDecrypt(responseDataEnc.data)
        responseData.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});



export const postDiabetesData = createAsyncThunk('DiabetesForm/postDiabetesData', async (data) => {
    const Encparams = AesEncrypt(data)
    console.log('datadatadatadatadatadatadata',data);
    
    try {
        const response = await fetch(webservice + 'api/addBloodSugar', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});
export const diabetesEdit = createAsyncThunk('dataTable/diabetesEdit', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/updateBloodSugar', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});
export const diabetesDelete = createAsyncThunk('dataTable/diabetesDelete', async (id) => {
    const requestData = {
        _id: id, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));

    try {
        const response = await fetch(webservice + 'api/deleteBloodSugar', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const DocumentUploadSlice = createSlice({
    name: 'bloodpressure',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getAllData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})