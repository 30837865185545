import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// import MicrosoftTeamsImage from './Demographic-images/Wedding 5.png';
import { useTranslation } from 'react-i18next';

const MaritalStatusPage = ({ handleNextClick, value, setvalue }) => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const maritalFromStorage = localStorage.getItem('maritial');
    if (maritalFromStorage) {
      setSelectedStatus(maritalFromStorage);
      setErrorMessage('')

    }
  }, []);

  const handleClick = (status) => {
    setSelectedStatus(status);
    setErrorMessage('')

    // Call handleNextClick after a delay

  };


  const handleSubmit = () => {
    if (!selectedStatus || selectedStatus === '') {
      setErrorMessage(t('Please select your marital status'));
      setvalue(4)
    } else {
      localStorage.setItem('maritial', selectedStatus);
      handleNextClick();
    }
  }

  useEffect(() => {
    if (value === 5) {
      handleSubmit();
    }
  });


  return (
    <>
      <div  className='D_container'>
        <div className='D_column'>
        <div className='demography_main_contant_maritial'>
        <h2   style={{color:"black",marginTop:'10%'}}>{t('Choose your marital status')}</h2>

        <div className="single_lable">
          <label htmlFor="single">
            <div className={`Single ${selectedStatus === 'Single' ? 'selected' : ''} `} onClick={() => handleClick('Single')}>
              <span>{t('Single')}</span>
            </div>
          </label>
        </div>

        <div className="married_lable">
          <label htmlFor="married">
            <div className={`Married ${selectedStatus === 'Married' ? 'selected' : ''} `} onClick={() => handleClick('Married')}>
              <span>{t('Married')}</span>
            </div>
          </label>
        </div>


        <div className="widow_lable">
          <label htmlFor="widow">
            <div className={`Widow ${selectedStatus === 'Widow' ? 'selected' : ''} `} onClick={() => handleClick('Widow')}>
              <span>{t('Widow')}</span>
            </div>
          </label>
        </div>


        <div className="divorced_lable">
          <label htmlFor="divorced">
            <div className={`Divorced ${selectedStatus === 'Divorced' ? 'selected' : ''}`} onClick={() => handleClick('Divorced')}>
              <span>{t('Divorced')}</span>
            </div>
          </label>
        </div>


        {errorMessage && <p id='maritial-error-message' className="notification-text error error-message">{errorMessage}</p>}
      </div>

      </div>
      </div>
    </>
  );
};

export default MaritalStatusPage;
