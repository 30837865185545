import React, { useState, useEffect, Fragment } from "react";
import { getAllData, getDob } from "./store/index";
import { useDispatch } from "react-redux";
import { Col, Card, Table, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import "../../../css/custom.css";
import { AesDecrypt } from "../../../services/crypto";
import Breadcrumbs from '../../breadcrumbs'
const Vaccination = () => {
  const [allData, setAllData] = useState([]);
  const [showAllData, setShowAllData] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("");
  const [dob, setDob] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const id = localStorage.getItem('id');
      if (id !== "undefined") {
        const response = await dispatch(getDob(id));
        if (response.payload && response.payload.DOB) {
          const decryptedDOB = AesDecrypt(response.payload.DOB);
          setDob(decryptedDOB);
        }
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getAllData());
      if (response.payload && response.payload.data) {
        const filteredData = !showAllData ? response.payload.data.filter(item => {
          return item.startIndex <= calculateAgeInDays(new Date(dob)) &&
            item.endIndex >= calculateAgeInDays(new Date(dob));
        }) : response.payload.data;
        const roundedData = filteredData.map(item => ({
          ...item,
          startAge: calculateRoundedAge(item.startIndex),
          endAge: calculateRoundedAge(item.endIndex)
        }));
        setAllData(roundedData);
        setIsLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchData();
  }, [dispatch, dob, showAllData]);

  function calculateAgeInDays(birthday) {
    const ageInMilliseconds = Date.now() - birthday.getTime();
    const ageInDays = ageInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.floor(ageInDays);
  }

  function calculateRoundedAge(age) {
    if (age < 365) {
      return `${age} ${t('days')}`;
    }
    return Math.round(age / 365);
  }

  const toggleShowAllData = () => {
    setShowAllData(!showAllData);
    setButtonLabel(showAllData ? t("All Vaccination list") : t("Current Age"));
  };

  useEffect(() => {
    setButtonLabel(t("All Vaccination list"));
  }, [t]);

  return (
    <Fragment>
      <Breadcrumbs title={t('Vaccine Details')} data={[{ title: t('Vaccination') }]} />
      <Col lg={12}>
        <Card style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
          <Card.Header style={{display:'flex',justifyContent:'space-between'}}>
            <div>
              <h1>{t('Vaccination')}</h1>

            </div>
            <div>
              <Button style={{width:'100%'}} onClick={toggleShowAllData}>
                {buttonLabel}
              </Button>
            </div>

          </Card.Header>
          <Card.Body>
            {isLoading ? ( // Conditional rendering based on loading state
              <div>Loading...</div>
            ) : allData.length === 0 ? ( // Display message when no data found
              <div className="text-black">{t('No vaccines found for your age group')}</div>
            ) : (
              <Table responsive className="header-border" style={{ color: "black" }}>
                <thead>
                  <tr>
                    <th>{t('Age')}</th>
                    <th>{t('Gender')}</th>
                    <th>{t('Vaccines')}</th>
                    <th>{t('Dose')}</th>
                    <th>{t('Site')}</th>
                    <th>{t('Route')}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((item, index) => (
                    <tr key={index} className="text-black">
                      <td>{item.startAge}-{item.endAge}</td>
                      <td>{item.Gender.join(" / ")}</td>
                      <td>{item.Vaccines}</td>
                      <td>{item.Dose}</td>
                      <td>{item.Site}</td>
                      <td>{item.Route}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Vaccination;
