import React, { useState, useEffect, Fragment } from "react";
import { Line } from 'react-chartjs-2';
import { useDispatch } from 'react-redux'; // Assuming you're using Redux
import { getbloodsugarchart } from './store'; // Import your action for fetching data

import 'chart.js/auto';
import { AesDecrypt } from "../../../services/crypto";
import { Link } from "react-router-dom";
import { Label } from "recharts";
import entry from "react-time-picker";
import { t } from "i18next";

const LineChart1 = () => {
  // const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const dispatch = useDispatch();
  const [showChart, setShowChart] = useState(false);
  const [diabetes, setDiabets] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const _id = localStorage.id;
        const res = await dispatch(getbloodsugarchart(_id));
        const tempdata = AesDecrypt(res.payload.data)
        if (Array.isArray(tempdata) && tempdata.length !== 0) {
          setDiabets(tempdata)
          setShowChart(true);

        }
        else {
          setShowChart(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error
      }
    };

    fetchData();
  }, [dispatch]);

  const chartData = {
    labels: diabetes.map((entry) => entry.testDate),
    datasets: [
      {
        label: "Sugar Level",
        data: diabetes.map((entry) => entry.sugarLevel),
        borderColor: "rgba(160, 44, 250)",
        borderWidth: "2",
        backgroundColor: "rgba(160, 44, 250)",
        tension: 0.4
      }
    ]
  }


  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          fontSize: 12,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          mode: 'horizontal',
          scaleID: 'x',
          value: 50,
          borderColor: 'rgb(75, 192, 192)',
          borderWidth: 2,
          label: {
            enabled: true,
            content: 'No data available',
            position: 'center',
          }
        }]
      },
      tooltips: {
        intersect: false,
      },
      hover: {
        intersect: true,
      },
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 20,
          padding: 10,
        },
        display: true,
      },
      x: {
        ticks: {
          padding: 5,
        },
        display: true,
      },
    },
  };

  return (
    <Fragment>
      <Line
        data={chartData}
        options={options}
        height={150}
      />
      {!showChart && (
        <Link to={'/Diabetes'}>
          <div className="BP_nodata_container">
            <div  style={{ }} className="BP_contant_container">
              <h3 className="bp-header-font" >{t('No data available to display')}</h3>
              <h5 className="bp-header-font" >{t('Click here to track your Diabets')}</h5>
            </div>
          </div>
        </Link>
      )}
    </Fragment>
  );
}

export default LineChart1;