import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AesDecrypt } from "../../../services/crypto";
import { getMentalhealth } from './store';
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../breadcrumbs";
import { useTranslation } from "react-i18next";

import depression from '../../../images/iconly/bulk/Distress.svg';
import anxiety from '../../../images/iconly/bulk/Anxiety.svg';
import stress from '../../../images/iconly/bulk/Sadness.svg';

const DASHOME = () => {
  const [data, setData] = useState();
  const _id = localStorage.id;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMentalhealth(_id))
      .then((res) => {
        const tempdata = res.payload.data;
        if (tempdata && tempdata.length !== 0) {
          const data = AesDecrypt(tempdata);
          setData(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch, _id]);

  const calculateScore = (scoreData, type) => {
    const scores = Object.values(scoreData).map(Number);

    let totalScore = scores.reduce((acc, score) => acc + score, 0);

    if (totalScore === 0) {
      totalScore = 5; // Consider 0 as 5
    }
    let adjustedScore;

    if (type === "depression") {
      adjustedScore = (totalScore * 100) / 27;
    } else if (type === "anxiety") {
      adjustedScore = (totalScore * 100) / 21;
    } else if (type === "stress") {
      adjustedScore = (totalScore * 100) / 40;
    }
    return Math.round(adjustedScore); // Return rounded score
  };

  const getAnxietySeverity = (score) => {
    if (score >= 0 && score <= 5) return t("None-minimal");
    if (score >= 6 && score <= 25) return t("Mild");
    if (score >= 26 && score <= 50) return t("Moderate");
    if (score >= 51 && score <= 75) return t("Moderately Severe");
    if (score >= 76 && score <= 100) return t("Severe");
    return "Unknown";
  };

  const getDepressionSeverity = (score) => {
    if (score >= 0 && score <= 5) return t("None-minimal");
    if (score >= 6 && score <= 25) return t("Mild");
    if (score >= 26 && score <= 50) return t("Moderate");
    if (score >= 51 && score <= 75) return t("Moderately Severe");
    if (score >= 76 && score <= 100) return t("Severe");
    return "Unknown";
  };

  const getStressSeverity = (score) => {
    if (score >= 0 && score <= 5) return t("None-minimal");
    if (score >= 6 && score <= 25) return t("Mild");
    if (score >= 26 && score <= 50) return t("Moderate");
    if (score >= 51 && score <= 75) return t("Moderately Severe");
    if (score >= 76 && score <= 100) return t("Severe");
    return "Unknown";
  };

  let anxietyScore = 0;
  let anxietySeverity = "Unknown";
  let depressionScore = 0;
  let depressionSeverity = "Unknown";
  let stressScore = 0;
  let stressSeverity = "Unknown";

  if (data) {
    if (data.anxiety) {
      anxietyScore = calculateScore(data.anxiety, "anxiety");
      anxietySeverity = getAnxietySeverity(anxietyScore);
    }
    if (data.depression) {
      depressionScore = calculateScore(data.depression, "depression");
      depressionSeverity = getDepressionSeverity(depressionScore);
    }
    if (data.stress) {
      stressScore = calculateScore(data.stress, "stress");
      stressSeverity = getStressSeverity(stressScore);
    }
  }

  return (
    <Fragment>
      <BreadCrumbs title={t('MentalHealth')} data={[{ title: t('MentalHealth') }]} />
      <div className="row">
        <div className="col-xl-4 col-lg-6">
          <div className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
            <div className="card-header pb-0 border-0">
              <div className="me-auto pr-3">
                <h4 className="text-black fs-30" >{t('Depression')}</h4>
                <img src={depression} alt="Depression" style={{ width: '100px', height: '100px' }} />
              </div>
              <Button tag={Link} to={'/depression'}>{t('Take Test')}</Button>
            </div>
            <div className="card-body pb-0">
              {depressionScore > 0 && (
                <>
                  <h5 className="text-black fs-20">{t('Last Testdate :')} {data.dateKey}</h5>
                  <h5 className="text-black fs-20">{t('Depression score :')} {depressionScore}</h5>
                  <h5 className="text-black fs-20">{t('Depression Level :')} <span style={{ color: 'blue' }}>{depressionSeverity}</span> </h5>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <div className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
            <div className="card-header pb-0 border-0">
              <div className="me-auto pr-3">
                <h4 className="text-black fs-30">{t('Anxiety')}</h4>
                <img src={anxiety} alt="Anxiety" style={{ width: '100px', height: '100px' }} />
              </div>
              <Button tag={Link} to={'/anxiety'}>{t('Take Test')}</Button>
            </div>
            <div className="card-body pb-0">
              {anxietyScore > 0 && (
                <>
                  <h5 className="text-black fs-20">{t('Last Testdate :')} {data.dateKey}</h5>
                  <h5 className="text-black fs-20">{t('Anxiety score :')} {anxietyScore}</h5>
                  <h5 className="text-black fs-20">{t('Anxiety Level :')} <span style={{ color: 'blue' }}>{anxietySeverity}</span> </h5>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <div className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
            <div className="card-header pb-0 border-0">
              <div className="me-auto pr-3">
                <h4 className="text-black fs-30">{t('Stress')}</h4>
                <img src={stress} alt="Stress" style={{ width: '100px', height: '100px' }} />
              </div>
              <Button tag={Link} to={'/stress'}>{t('Take Test')}</Button>
            </div>
            <div className="card-body pb-0">
              {stressScore > 0 && (
                <>
                  <h5 className="text-black fs-20">{t('Last Testdate :')} {data.dateKey}</h5>
                  <h5 className="text-black fs-20">{t('Stress Score :')} {stressScore}</h5>
                  <h5 className="text-black fs-20">{t('Stress Level :')} <span style={{ color: 'blue' }}>{stressSeverity}</span> </h5>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DASHOME;
