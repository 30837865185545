import { AesEncrypt } from '../../../../services/crypto';
import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getDob = createAsyncThunk('UserType/getPassword', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/getDob', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});



export const getdata = createAsyncThunk('Medical/getdata', async () => {
    try {
        const response = await fetch(webservice + 'api/medicalChecklist', {
            method: 'POST', // Change method to GET
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
  
        const responseData = await response.json();
        
  
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
  });

  export const testdetails = createAsyncThunk('Medical/testdetails', async (id) => {
    try {
        const response = await fetch(webservice + 'api/medicalCheckdetails', {
            method: 'POST',
            body: JSON.stringify({id}), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
  
        const responseData = await response.json();
  
  
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
  });