import React, { useState, Fragment, useEffect } from "react";
import { Button, Card, Modal, ModalHeader, ModalBody, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteMood, RecentMood } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import "../../../css/custom.css";
import BreadCrumbs from "../../breadcrumbs";
import moment from 'moment';
import Swal from "sweetalert2";

const MoodMonitor = () => {
  const [RecentData, setRecentData] = useState([]);
  const dispatch = useDispatch();
  const userId = localStorage.id;
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(RecentMood({ userId })).then((response) => {
      const MoodData = AesDecrypt(response.payload.data);
      setRecentData(MoodData);
    });
    // },);
  }, [dispatch, userId]);

  const formatDate = (dateString) => {
    const date = moment(dateString, "DD-MM-YYYY HH:mm:ss");
    if (!date.isValid()) {
      return "Invalid Date";
    }
    return date.format("DD MMM, YYYY");
  };

  const [expandedReason, setExpandedReason] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalReason, setModalReason] = useState("");
  const [selectedMood, setSelectedMood] = useState("");

  const toggleReason = (index) => {
    setExpandedReason({
      ...expandedReason,
      [index]: !expandedReason[index]
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleReadMore = (item) => {
    if (item.note.trim() === '') {
      setModalReason(getEmptyReason(item.mood));
    } else {
      setModalReason(item.note);
    }
    setSelectedMood(item.mood);
    toggleModal();
  };

  const getEmptyReason = (mood) => {
    switch (mood) {
      case "Anger":
        return "BOT: Take a moment to pause and reflect on your anger, then channel it into positive and constructive actions.";
      case "Fear":
        return "BOT: Embrace courage and take a step forward despite your fears.";
      case "Sadness":
        return "BOT: Courageously face your fears and move forward with confidence.";
      case "Happy":
        return "BOT: Spread joy and share your happiness with others.";
      case "Cheerful":
        return "BOT: Spread your joy and uplift those around you with your cheerful spirit.";
      case "Romantic":
        return "BOT: Express your love openly and wholeheartedly, cherishing every moment together.";
      case "Madness":
        return "BOT: Find peace within yourself and seek clarity through mindfulness and self-reflection.";
      case "Calm":
        return "BOT: Continue to cultivate and spread your inner calm.";
      case "NegativeMood":
        return "BOT: Find moments of joy and gratitude, even in the midst of negativity.";
      case "Anxious":
        return "BOT: Focus on the present moment and engage in calming activities to soothe your anxiety.";
      case "Irritability":
        return "BOT: Practice patience and find ways to calmly address your concerns.";
      case "Disgust":
        return "BOT: Find the beauty in every situation and approach it with an open mind and heart.";
      default:
        return "N/A";
    }
  };

  const moodColors = {
    Anger: "red",
    Fear: "orange",
    Sadness: "gray",
    Happy: "#1eac00",
    Cheerful: "blue",
    Romantic: "#ff8096",
    Madness: "#b0b058",
    Calm: "#1eac00",
    NegativeMood: "red",
    Anxious: "#b0b058",
    Irritability: "red",
    Disgust: "orange"
  };

  const moodOptions = [
    { value: "Anger", label: t("Anger"), emoji: "😡" },
    { value: "Fear", label: t("Fear"), emoji: "😨" },
    { value: "Sadness", label: t("Sadness"), emoji: "😔" },
    { value: "Happy", label: t("Happy"), emoji: "😁" },
    { value: "Cheerful", label: t("Cheerful"), emoji: "💐" },
    { value: "Romantic", label: t("Romantic"), emoji: "🥰" },
    { value: "Madness", label: t("Madness"), emoji: "🤯" },
    { value: "Calm", label: t("Calm"), emoji: "😌" },
    { value: "Negative", label: t("Negative"), emoji: "🤬" },
    { value: "Anxious", label: t("Anxious"), emoji: "🥴" },
    { value: "Irritability", label: t("Irritability"), emoji: "😬" },
    { value: "Disgust", label: t("Disgust"), emoji: "🤢" }
  ];

  const reasonOptions = [
    { value: "Family", label: t("Family") },
    { value: "Friend", label: t("Friend") },
    { value: "School", label: t("School") },
    { value: "College", label: t("College") },
    { value: "Education", label: t("Education") },
    { value: "Love", label: t("Love") },
    { value: "Care", label: t("Care") },
    { value: "Others", label: t("Others") },
  ];


  const handleDeleteMood = (Mood_id) => {
    dispatch(DeleteMood(Mood_id)).then(() => {
      dispatch(RecentMood({ userId })).then((response) => {
        const MoodData = AesDecrypt(response.payload.data);
        setRecentData(MoodData);
      });
    });
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: 'Do you really want to delete this mood?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMood(id);
        Swal.fire(
          'Deleted!',
          'The mood has been deleted.',
          'success'
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'The mood is safe :)',
          'error'
        );
      }
    });
  };

  return (
    <>

      <Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className=''>
          <div className="mood-breadcrumbs-minwidth"  >
            <BreadCrumbs title={t('Mood Monitor')} data={[{ title: t('Mood Monitor') }]} />
          </div>
          <div>
            <Button tag={Link} to={'/mentalhealth'}>{t('Check your Mentalhealth')}</Button>
          </div>
        </div>
        <br />
        <Card>
          <CardBody>
            <div className=" card-header-custom-mood">
              <h2>{t('Recent Mood')}</h2>
              <span>
                <Link to={`/add_mood`}>
                  <Button className="circle-button" color="primary" >+</Button>
                </Link>
              </span>
            </div>

            <div className="row">
              {Array.isArray(RecentData) && RecentData.length === 0 ? (
                <h3 style={{ textAlign: 'center',color: 'grey' }}>No Mood Data Post available</h3>
              ) : (
                Array.isArray(RecentData) && RecentData.map((item, index) => (
                  <div className="mood-responsive-card" key={index}>
                    <Card style={{ padding: '4%' }} className="card1">
                      <div className="card-header-custom-mood">
                        <h4 className=" "> {formatDate(item.time)}</h4>
                        <span title={t('Delete')}
                          onClick={() => handleDeleteClick(item._id)}
                          className="btn btn-danger shadow btn-xs sharp">
                          <i className="fa fa-trash"></i>
                        </span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h4 className="fs-20 font-w600"> {t(item.mood)}</h4>
                        {moodOptions.map((moodOption) =>
                          moodOption.value === item.mood ? (
                            <span className=" " key={moodOption.label}>
                              {moodOption.emoji}
                            </span>
                          ) : null
                        )}
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h4 className="fs-20 font-w600  "> {t(item.reason)}</h4>
                        {reasonOptions.map((reasonOption) =>
                          reasonOption.label === item.reason ? (
                            <span className=" " key={reasonOption.label}>
                              {reasonOption.emoji}
                            </span>
                          ) : null
                        )}
                      </div>
                      {/* <br/> */}
                      &nbsp;
                      <h4 style={{ color: moodColors[item.mood] }}>
                        {item.note.trim() !== '' ? (
                          (item.note.length > 35 && !expandedReason[index]) ? (
                            <div style={{ color: moodColors[item.mood] }} className="mood-ReadMore" onClick={() => handleReadMore(item)}>
                              {`${item.note.substring(0, 35)}... `}{t('Read More')}
                            </div>
                          ) : (
                            item.note
                          )
                        ) : (
                          <div style={{ color: moodColors[item.mood] }} className="mood-ReadMore" onClick={() => handleReadMore(item)}>
                            {getEmptyReason(item.mood).split(' ').slice(0, 4).join(' ')}
                            {t('...Read More')}
                          </div>
                        )}
                      </h4>
                    </Card>
                  </div>
                )))}
            </div>
            <Modal isOpen={modalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}><strong>{t(selectedMood)}</strong></ModalHeader>
              <ModalBody>
                <p>{modalReason}</p>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default MoodMonitor;
