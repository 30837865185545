import React, { useEffect, useState } from 'react';

import MicrosoftTeamsImage from './Demographic-images/Emailimage.png';
import { useTranslation } from 'react-i18next';
import { checkEmailAction } from '../../../store/actions/AuthActions';
import { useDispatch } from 'react-redux';

const EmailID = ({ handleNextClick, value, setvalue }) => {
    const [email, setEmail] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrorMessage('');
    }

    useEffect(() => { 
        if (email !== 'undefined') {
            setEmail(localStorage.getItem('email'))
            setErrorMessage('')
        }
    }, []);

    
    const handleSubmit = async () => {
        if (email !== null && isValidEmail(email)) {
            const res = await dispatch(checkEmailAction(email))
            if (res.response === 'F') {
                setvalue(0)
                setErrorMessage(t('Email is already exist'));
            } else {
                localStorage.setItem('email', email);
                handleNextClick();
            }
        } else {
            setvalue(0)
            setErrorMessage(t('Please provide a valid email'));
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    useEffect(() => {
        if (value === 1) {
            handleSubmit();

        }
    });

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    return (
        // <>
        //     <div className='Demography_container'>
        //             <img className='Demograhy_image' src={MicrosoftTeamsImage} alt="Animated Image" />
        //         <div className="demography_contant">
        // <h4  className="text-black" >{t('What is your email?')}</h4>
        // <input
        //     placeholder={t('Type your email Here')}
        //     type="text"
        //     value={email || ''}
        //     onChange={handleEmailChange}
        //     onKeyDown={handleKeyDown}
        //     className="name-input"
        // />
        // {errorMessage && <p style={{ marginLeft: '3%', position: 'relative', bottom: '15px' }} className="error-message">{errorMessage}</p>}
        //         </div>
        //     </div>
        // </>
        <div className='D_container'>

            <div className='D_column D_img_container'>
                <img className='Demograhy_image' src={MicrosoftTeamsImage} alt="Animated Image" style={{ height: "auto", width: "100%" }} />
            </div>

            <div  className='D_column D_contant_container demography_card'>
                <div id='Demography_card'>
                    {/* <div className='demography_main_contant'> */}
                    <h4 style={{color:"black"}} >{t('What is your email?')}</h4>
                      <input
                    placeholder={t('Type your email Here')}
                    type="text"
                    value={email || ''}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyDown}
                    className="name-input"
                />
                {errorMessage && <p  className="error-message">{errorMessage}</p>}
                {/* {errorMessage && <p style={{ marginLeft: '3%', position: 'relative', bottom: '15px' }} className="error-message">{errorMessage}</p>} */}
                    {/* </div> */}
                
                </div>
            </div>

        </div>



    );
}
export default EmailID;
