import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Table, Button, Modal, CardBody } from "react-bootstrap";
import { updateBloodPressureData, deleteBloodPressureData } from "./store";
import DatePicker from "react-datepicker";
import "../../../css/custom.css";
import webservice from '../../../services/webservice';
import { useTranslation } from 'react-i18next';
import { postBloodPressureData } from "./store/index";
import { Badge, Input, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
const BloodPressureTable = ({ alldata, handleReload }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const moment = require('moment');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const dispatch = useDispatch();
  const [deletedId, setDeletedId] = useState(null);
  const { t } = useTranslation();
  const [basicModal, setBasicModal] = useState(false);
  const [systolic, setSystolic] = useState('');
  const [diastolic, setDiastolic] = useState('');
  const [pulse, setPulse] = useState('');
  const [systolicValue, setSystolicValue] = useState('');
  const [diastolicValue, setDiastolicValue] = useState('');
  const [pulseValue, setPulseValue] = useState('');
  const defalutDate = moment(new Date()).format('DD-MM-YYYY')
  const [date, setDate] = useState(defalutDate);
  const [errors, setErrors] = useState({});
  const [systolicCategory, setSystolicCategory] = useState('');
  const userId = localStorage.id
  const [pulseCategory, setPulseCategory] = useState('');
  const [systalictooltipContent, setSystalicTooltipContent] = useState('');
  const [diastolictooltipContent, setDiastolicTooltipContent] = useState('');
  const [pulsetooltipContent, setPulseTooltipContent] = useState('');
  const [age, setAge] = useState('');
  const handleEdit = (index, data) => {
    setEditingIndex(index);
    setEditedData({ ...data });
  };


  const handleDelete = (index) => {
    setDeleteIndex(index);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = (id) => {
    setDeletedId(id); // Set the deleted ID state
    setShowDeleteModal(false);
    dispatch(deleteBloodPressureData(id)).then((response) => {
      handleReload()
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };


  const handleSave = (index) => {
    // Implement saving edited data logic here
    const { ...editedFields } = editedData;
    dispatch(updateBloodPressureData({ data: editedFields })).then((responce) => {
      handleReload()
    });
    setEditingIndex(null);

  };
  const handlediscard = (index) => {
    // Implement saving edited data logic here
    setEditingIndex(null);

  };


  const handleInputChange = (value, key) => {
    setEditedData(prevData => ({
      ...prevData,
      [key]: value
    }));

    // Update systolic category based on input value
    if (key === 'systolic') {
      const systolicValue = parseInt(value);
      if (systolicValue < 120) {
        setSystolicCategory('normal');
      } else if (systolicValue >= 120 && systolicValue <= 129) {
        setSystolicCategory('elevated');
      } else {
        setSystolicCategory('hypertensive');
      }
    }
  };

  const handleEditDateChange = (date1) => {
    let date = new Date(date1);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = day + "-" + month + "-" + year;
    setEditedData(prevData => ({
      ...prevData,
      'testDate': formattedDate
    }));
  };

  // const handleDateChange = (date1) => {
  //   let date = new Date(date1);
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const year = date.getFullYear();
  //   const formattedDate = day + "-" + month + "-" + year;
  //   setDate(formattedDate);
  // };

  const handleDateChange = (date1) => {
    setDate(moment(date1).format ('DD-MM-YYYY'))
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userID = localStorage.getItem('id');
        const response = await fetch(webservice + 'api/bmiValue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userID: userID })
        });
        const data = await response.json();
        const dob = data.user.dob;

        // Format dob into a valid date string
        const dobParts = dob.split('-');
        const formattedDOB = dobParts[1] + '-' + dobParts[0] + '-' + dobParts[2];
        const birthDate = new Date(formattedDOB);

        // Calculate age from date of birth
        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();

        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        // Set the age using setAge
        if (!isNaN(age)) {
          setAge(age);
        } else {
          // console.error('Invalid age:', age);
        }
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchData, 1000); // Fetch data every 1 second
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);



  const handlesystalicinfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your systolic pressure should be between 75 mmHg to 110 mmHg.';
    } else if (age1 >= 13 && age1 <= 60) {
      suggestion = 'Your systolic pressure should be between 90mmHg to 115 mmHg.';
    } else if (age1 >= 61 && age1 <= 64) {
      suggestion = 'Your systolic pressure should be between 121mmHg to 147 mmHg.';
    } else {
      suggestion = 'Systolic range for this age group is not available.';
    }
    setSystalicTooltipContent(suggestion);
  };

  const handlediastolicinfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your diastolic pressure should be between 50 mmHg to 75 mmHg.';
    } else if (age1 >= 13 && age1 <= 60) {
      suggestion = 'Your diastolic pressure should be between 60 mmHg to 80 mmHg.';
    } else if (age1 >= 61 && age1 <= 64) {
      suggestion = 'Your diastolic pressure should be between 83 mmHg to 91 mmHg.';
    } else {
      suggestion = 'Diastolic range for this age group is not available.';
    }
    setDiastolicTooltipContent(suggestion);
  };

  const handlePulseInfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your pulse rate should be between 70 to 110 beats per minute.';
    } else if (age1 >= 13 && age1 <= 60) {
      suggestion = 'Your pulse rate should be between 60 to 100 beats per minute.';
    } else if (age1 >= 61 && age1 <= 64) {
      suggestion = 'Your pulse rate should be between 58 to 100 beats per minute.';
    } else {
      suggestion = 'Pulse rate range for this age group is not available.';
    }

    setPulseTooltipContent(suggestion);
  };


  const handleSystolicChange = (value) => {
    if (value === '') {
      setSystolicValue('')
    }
    // Check if the entered value is a valid number
    if (isNaN(parseFloat(value))) {
      setErrors({ systolic: t('') });
    } else {
      const systolicValue = parseFloat(value);
      if (value.trim() !== '') {
        // If the value is empty, don't set any errors
        setErrors({ systolic: '' });
      }
      if (systolicValue < 0) {
        setErrors({ systolic: t('Systolic Pressure cannot be negative') });
      }
      else if (systolicValue < 121) {
        setSystolicValue(t('Systolic Pressure is Normal'));
      } else if (systolicValue < 130) {
        setSystolicValue(t('Systolic Pressure is Elevated'));
      } else if (systolicValue < 140) {
        setSystolicValue(t('Hypertension Stage 1'));
      } else if (systolicValue < 180) {
        setSystolicValue(t('Hypertension Stage 2'));
      } else {
        setSystolicValue(t('Hypertensive Crisis, Consult your doctor immediately'));
      }
    }
  };

  const handleDiastolicChange = (value) => {
    if (value === '') {
      setDiastolicValue('')
    }
    // Check if the entered value is a valid number
    if (isNaN(parseFloat(value))) {
      setErrors(prevErrors => ({
        ...prevErrors,
        diastolic: t('')
      }));
    } else {
      const diastolicValue = parseFloat(value);
      // Validate against blood pressure categories

      if (value.trim() !== '') {
        setErrors(prevErrors => ({
          ...prevErrors,
          diastolic: t(' ')
        }))
      }
      else if (diastolicValue < 0) {
        setErrors(prevErrors => ({
          ...prevErrors,
          diastolic: t('Diastolic Pressure cannot be negative')
        }));

      }
      if (value.trim() === '') {
        setDiastolicValue((
          t('Enter value ')
        ));
      } if (diastolicValue <= 49) {
        setDiastolicValue(t(''));
      } else if (diastolicValue >= 50 && diastolicValue <= 69) {
        setDiastolicValue(t('Low diastolic value'));
      }
      else if (diastolicValue >= 70 && diastolicValue <= 80) {
        setDiastolicValue(t('Diastolic Pressure is Normal'));
      }
      else if (diastolicValue > 80 && diastolicValue <= 90) {
        setDiastolicValue(t('Hypertension Stage 1'));
      } else if (diastolicValue > 90 && diastolicValue <= 120) {
        setDiastolicValue(t('Hypertension Stage 2'));
      } else {
        setDiastolicValue(t('Hypertensive Crisis, Consult your doctor immediately'));
      }
    }
  };

  const handlePulseChange = (value) => {
    // const numericValue = e.target.value.replace(/\D/g, '');



    if (value === '') {
      setPulseCategory('')

    }
    if (isNaN(parseFloat(value))) {
      setErrors(prevErrors => ({
        ...prevErrors,
        pulse: t('')
      }));
    } else {
      const pulseValue = parseFloat(value);
      if (value.trim() !== '') {
        setErrors(prevErrors => ({
          ...prevErrors,
          pulse: t('')
        }))
      }
      else if (pulseValue < 0) {
        setErrors(prevErrors => ({
          ...prevErrors,
          pulse: t('Pulse cannot be negative')
        }));
      } if (pulseValue >= 60 && pulseValue <= 100) {
        setPulseCategory(prevErrors => ({
          ...prevErrors,
          pulse: t('Pulse is Normal')
        }));
        setPulseCategory('normal'); // Update pulseCategory state variable
      } else if ((pulseValue >= 100 && pulseValue <= 205) || (pulseValue >= 100 && pulseValue <= 180) || (pulseValue >= 98 && pulseValue <= 140) || (pulseValue >= 80 && pulseValue <= 120) || (pulseValue >= 75 && pulseValue <= 118) || (pulseValue >= 60 && pulseValue <= 100)) {
        setPulseCategory(prevErrors => ({
          ...prevErrors,
          pulse: t('Pulse is Elevated')
        }));
        setPulseCategory('elevated'); // Update pulseCategory state variable
      } else {
        setPulseCategory(prevErrors => ({
          ...prevErrors,
          pulse: t('Hypertensive Range')
        }));
        setPulseCategory('hypertensive'); // Update pulseCategory state variable
      }
    }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    // Check for empty fields
    const errors = {};

    if (!systolic.trim()) {
      errors.systolic = t('Systolic Pressure is required');
    }
    if (!diastolic.trim()) {
      errors.diastolic = t('Diastolic Pressure is required');
    }
    if (!pulse.trim()) {
      errors.pulse = t('Pulse is required');
    }
    if (!date || date === '') {
      errors.testDate = t('Date is required');
    }

    if (Object.keys(errors).length === 0) {
      // Dispatch the action and await its completion
      dispatch(postBloodPressureData({ systolic, diastolic, pulse,'testDate': date, userId })).then((responce) => {
        handleReload()
      });

      setBasicModal(false);
      setSystolicValue('')
      setDiastolicValue('')
      setPulseCategory('')
      setSystolic('')
      setDiastolic('');
      setPulse('');
      setDate('');
    } else {
      setErrors(errors);
    }
  };


  const handleClose = async (e) => {
    setBasicModal(false);
    setSystolicValue('')
    setDiastolicValue('')
    setPulseCategory('')
    setSystolic('')
    setDiastolic('');
    setPulse('');
    setErrors('')
    setDate('');
  }


  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly
      className="input-field"
    />
  ));

  return (
    <>
      <div className="bpsugar-responsive-add-button">
        <Button onClick={() => setBasicModal(true)} className="circle-button_bp" color="primary">+</Button>
      </div>
      <Fragment>
        {/* <Row> */}
          {/* <CardBody> */}
            <div style={{ position: 'relative'}}>
              <Table style={{width:'98%',marginLeft:'1%',marginRight:'1%'}} responsive striped bordered hover>
                <thead className="text-wrap-responsive" >
                  <tr>
                    <th>{t('No')}</th>
                    <th>{t('TestDate')}</th>
                    <th>{t('Pulse')}</th>
                    <th>{t('Systolic')}</th>
                    <th>{t('Diastolic')}</th>
                    <th>{t('Edit')}</th>
                    {editingIndex !== null && (
                      <th>{t('Discard')}</th>
                    )}
                    {editingIndex === null && (
                      <th>{t('Delete')}</th>
                    )}
                  </tr>
                </thead>
                <tbody className="text-wrap-responsive" >
                  {Array.isArray(alldata) && alldata.length > 0 ? (
                    // {Array.isArray(alldata) &&
                    alldata.map((data, index) => (
                      <tr key={index} className="text-black">
                        <td>{index + 1}</td>
                        
                        <td>
                          {editingIndex === index ? (
                            <div style={{minWidth: '100%' }}>
                              <DatePicker
                                className="input-field"
                                selected={editedData.testDate ? new Date(editedData.testDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")) : null}
                                onChange={(date) => handleEditDateChange(date)}
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()} // Set maxDate to today's date
                                customInput={<CustomInput />}
                              />

                            </div>
                          ) : (
                            data.testDate
                          )}
                        </td>
                        <td>
                          {editingIndex === index ? (
                            <input
                              className="input-field"
                              type="text"
                              value={editedData.pulse || ''}
                              onChange={(e) => {
                                if (/^\d{0,3}$/.test(e.target.value)) {
                                  handleInputChange(e.target.value, 'pulse')
                                }
                              }
                              }
                            />
                          ) : (
                            data.pulse
                          )}
                        </td>
                        <td>
                          {editingIndex === index ? (
                            <input
                              className="input-field"
                              type="text"
                              value={editedData.systolic || ''}

                              onChange={(e) => {
                                if (/^\d{0,3}$/.test(e.target.value)) {
                                  handleInputChange(e.target.value, 'systolic')
                                }
                              }
                              }
                            />
                          ) : (
                            data.systolic
                          )}
                        </td>
                        <td>
                          {editingIndex === index ? (
                            <input
                              className="input-field"
                              type="text"
                              value={editedData.diastolic || ''}
                              onChange={(e) => {
                                if (/^\d{0,3}$/.test(e.target.value)) {
                                  handleInputChange(e.target.value, 'diastolic')
                                }
                              }
                              }
                            />
                          ) : (
                            data.diastolic
                          )}
                        </td>
                        <td>
                          {editingIndex === index ? (
                            <Badge color="success" className="btn-edit" onClick={() => handleSave(index)}>
                              {t('Save')}
                            </Badge>
                          ) : (
                            <Badge color="primary" className="btn-edit" onClick={() => handleEdit(index, data)}>
                              {t('Edit')}
                            </Badge>
                          )}
                        </td>
                        <td>
                          {editingIndex === index ? (
                            <Badge color="danger" className="btn-edit" onClick={() => handlediscard(index)}>
                              {t('Discard')}
                            </Badge>
                          ) : (
                            <Badge color="danger" className="btn-edit" onClick={() => handleDelete(index)}>
                              {t('Delete')}
                            </Badge>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p style={{ position: 'relative', left: '160%', top: '20px',textWrap:'nowrap' }}>
                      No data available to display
                    </p>
                  )}
                </tbody>
              </Table>
            </div>

            <Modal
              style={{
                position: 'fixed',
                top: '25%',
                left: '5%'
              }}
              show={showDeleteModal}  onHide={handleCloseDeleteModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t('Delete Confirmation')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t('Are you sure you want to delete this record?')}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeleteModal}>
                  {t('Cancel')}
                </Button>
                <Button variant="danger" onClick={() => handleConfirmDelete(alldata[deleteIndex]._id)}>{t('Delete')}</Button>
              </Modal.Footer>
            </Modal>
          {/* </CardBody> */}
          {/* <Card.Body> */}
            <div className="bootstrap-modal">
              <Modal className="fade " show={basicModal} onHide={() => setBasicModal(false)} backdrop="static">
                <Modal.Header>
                  <Card.Title className="text-black">{t('Enter Blood Pressure Details Here')}</Card.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className={`form-group ${errors.systolic ? 'has-error' : ''} text-black`}>
                    <label>{t('Systolic Pressure (mmHg) :')} <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: 'relative' }}>

                      <Input
                        type="text"
                        // className={`form-control ${errors.systolic ? 'is-invalid' : ''} text-black`}
                        className={`text-black`}
                        value={systolic}
                        onChange={(e) => {
                          if (/^\d{0,3}$/.test(e.target.value)) {
                            setSystolic(e.target.value);
                            handleSystolicChange(e.target.value);
                          }
                        }}
                        required
                      />
                      <span
                        style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 18 }}
                        onMouseEnter={handlesystalicinfo}
                      >
                        <i className="fa fa-info-circle" id="systolicInfoIcon">
                          <UncontrolledTooltip target="systolicInfoIcon">{systalictooltipContent}</UncontrolledTooltip>
                        </i>

                      </span>
                    </div>
                    <div className="Bpsuger_lable">
                      <label style={{ color: systolicValue.toLowerCase().includes('normal') ? 'green' : systolicValue.toLowerCase().includes('elevated') ? '#c39e19' : 'red' }}>{systolicValue}</label>
                    </div>
                    {errors.systolic && <div style={{ color: 'red', marginTop: '5px' }}>{errors.systolic}</div>}
                  </div>

                  <div className={`form-group${errors.diastolic ? ' has-error' : ''} text-black`}>
                    <label>{t('Diastolic Pressure (mmHg) :')} <span style={{ color: "red" }}>*</span>

                    </label>
                    <div style={{ position: 'relative' }}>

                      <Input
                        type="text"
                        // className={`form-control ${errors.systolic ? 'is-invalid' : ''} ${systolicCategory === 'normal' ? 'normal' : systolicCategory === 'elevated' ? 'elevated' : systolicCategory === 'hypertensive' ? 'hypertensive' : ''} text-black`}
                        className={`text-black`}
                        value={diastolic}
                        onChange={(e) => {
                          if (/^\d{0,3}$/.test(e.target.value)) {
                            setDiastolic(e.target.value);
                            handleDiastolicChange(e.target.value);
                          }
                        }}
                        required
                      />
                      <span
                        style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 18 }}

                        onMouseEnter={handlediastolicinfo}
                      >
                        <div>
                          <i className="fa fa-info-circle" id="diastolicInfoIcon">
                            <UncontrolledTooltip target="diastolicInfoIcon">{diastolictooltipContent}</UncontrolledTooltip>
                          </i>
                        </div>
                      </span>
                    </div>

                    <div className="Bpsuger_lable">
                      <label style={{ color: diastolicValue.toLowerCase().includes('normal') ? 'green' : diastolicValue.toLowerCase().includes('elevated') ? '#c39e19' : 'red' }}>{diastolicValue}</label>
                    </div>
                    {errors.diastolic && (
                      <div style={{ color: 'red', marginTop: '5px' }}>
                        {errors.diastolic}
                      </div>
                    )}
                  </div>

                  <div className={`form-group${errors.pulse ? ' has-error' : ''} text-black`}>
                    <label>{t('Pulse :')} <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: 'relative' }}>

                      <Input
                        type="text"
                        className={`text-black`}
                        // className={`form-control${errors.pulse ? ' is-invalid' : ''} text-black`}
                        value={pulse}
                        onChange={(e) => {
                          if (/^\d{0,3}$/.test(e.target.value)) {
                            setPulse(e.target.value);
                            handlePulseChange(e.target.value);
                          }
                        }}
                        required
                      />
                      <span
                        style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 18 }}
                        onMouseEnter={handlePulseInfo}
                      >
                        <div  >
                          < i className="fa fa-info-circle" id="pulseInfoIcon">
                            <UncontrolledTooltip target="pulseInfoIcon">{pulsetooltipContent}</UncontrolledTooltip>
                          </i>
                        </div>
                      </span>
                    </div>

                    <div className="Bpsuger_lable">
                      <label style={{ color: pulseCategory.toLowerCase() === 'normal' ? 'green' : pulseCategory.toLowerCase() === 'elevated' ? '#c39e19' : 'red', }}>{pulseCategory}</label>
                    </div>
                    {errors.pulse && (
                      <div style={{ color: 'red', marginTop: '5px' }}>
                        {errors.pulse}
                      </div>
                    )}
                  </div>

                  <div className={`form-group${errors.date ? ' has-error' : ''}`}>
                    <div>
                      <label className="text-black">{t('Date :')}</label>

                    </div>
                    <DatePicker
                      value={date ? date:moment(new Date()).format('DD-MM-YYYY')}
                      maxDate={new Date()}
                      onChange={(date) => handleDateChange(date)}
                      dateFormat="dd-MM-yyyy"
                      className={`form-control${errors.date ? ' is-invalid' : ''}  text-black`}
                    />
                    {errors.date &&
                      <div style={{ color: 'red', marginTop: '5px' }}>
                        {errors.date}</div>}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Row>
                    <Col>
                      <Button variant="primary" onClick={handleSubmit} className='text-center mt-2 pt-50' style={{ width: "100%" }}>
                        {t('Save')}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {/* <Button onClick={() => setBasicModal(false)} variant="danger" className='text-center mt-2 pt-50'> */}
                      <Button onClick={handleClose} variant="danger" className='text-center mt-2 pt-50'>
                        {t('Close')}

                      </Button>
                    </Col>
                  </Row>

                </Modal.Footer>
              </Modal>
            </div>
          {/* </Card.Body> */}
        {/* </Row> */}
      </Fragment>
    </>
  );
};


export default BloodPressureTable;