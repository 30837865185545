import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UpdateAction, updatemobile } from '../store';
import { AesDecrypt } from '../../../../services/crypto';
import Swal from 'sweetalert2';
import { MD5 } from 'crypto-js';


function Updateprofile(props) {
    const { id } = useParams();
    const { t } = useTranslation();
    const [mobile, setMobile] = useState('');
    const [password1, setPassword] = useState('');
    const [OtpData, setOtpData] = useState('');
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState({ mobile: '', password1: '', otp: '' });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    function Update(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { mobile: '', password1: '', otp: '' };

        if (mobile === '') {
            errorObj.mobile = t('Mobile number is required');
            error = true;
        } else if (mobile.length !== 10) {
            errorObj.mobile = t('Mobile number must be 10 digits');
            error = true;
        }

        if (password1 === '') {
            errorObj.password1 = t('Password is Required');
            error = true;
        }

        setErrors(errorObj);

        if (!error) {
            const data = { mobile, password1, id };
            dispatch(UpdateAction(data)).then((response) => {
                const update = response.payload;
                if (update.data) {
                    const otp = update.data
                    const OTP = AesDecrypt(otp)
                    setOtpData(OTP)
                }
                if (update.response === "S") {
                    Swal.fire({
                        title: t('Oops'),
                        text: t('Mobile Number already exists'),
                        icon: 'error',
                        confirmButtonText: t('Try Again!')
                    });
                } else {
                    setShowOtpInput(true); // Display OTP input field upon successful update
                }
            });
        }
    }

    const password = MD5(password1).toString();
    const data = { mobile, password, id };

    const verifyOTP = () => {
        if (otp === OtpData) {
            Swal.fire({
                title: "Success",
                text: t("OTP Verified Successfully"),
                icon: "success"
            });
            dispatch(updatemobile(data))
            navigate('/dashboard')
        } else {
            setErrors(prevErrors => ({ ...prevErrors, otp: 'Please enter Valid OTP' }));
        }
    }

    return (
        <Fragment>
            <div className="card col-lg-6 container justify-content-center " style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
                <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className='icon_backButton'>
                            <Link to={"/app-profile"}>
                                <i className="fas fa-arrow-alt-circle-left"></i>
                            </Link>
                        </div>
                        <div id="sign-in" >
                            <form onSubmit={Update} className="form-validate">
                                <h3 className="text-center mb-4 text-black">{t('Update User')}</h3>
                                {!showOtpInput && (
                                    <div>
                                        <div className="form-group mb-3">
                                            <label className="mb-1 text-black" htmlFor="val-mobile"><strong>{t('Mobile Number')}</strong></label>
                                            <div>
                                                <input
                                                    type="tel"
                                                    id="mobile"
                                                    className="form-control text-black"
                                                    value={mobile}
                                                    onChange={(e) => {
                                                        const numericValue = e.target.value.replace(/\D/g, '');
                                                        const limitedValue = numericValue.slice(0, 10);
                                                        setMobile(limitedValue);
                                                        setErrors(prevErrors => ({ ...prevErrors, mobile: '' }));
                                                    }}
                                                    placeholder={t("Type your mobile number")}
                                                />
                                                {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="mb-1 text-black"><strong>{t('Password')}</strong></label>
                                            <div className="input-group">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control text-black"
                                                    value={password1}
                                                    placeholder={t("Type your password")}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value)
                                                        setErrors(prevErrors => ({ ...prevErrors, password1: '' }));
                                                    }}
                                                />
                                                <div
                                                    className="input-group-text"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    <span>
                                                        {showPassword ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                                    </span>
                                                </div>
                                            </div>
                                            {errors.password1 && <span style={{ color: 'red' }}>{errors.password1}</span>}
                                        </div>
                                    </div>
                                )}
                                {showOtpInput && (
                                    <div className="form-group mb-3">
                                        <label className="mb-1 text-black"><strong>{t('OTP')}</strong></label>
                                        <input
                                            type="text"
                                            className="form-control text-black"
                                            value={otp}
                                            placeholder={t("Enter OTP")}
                                            onChange={(e) => {
                                                setOtp(e.target.value)
                                                setErrors(prevErrors => ({ ...prevErrors, otp: '' }));
                                            }}
                                        />
                                        {errors.otp && <span style={{ color: 'red' }}>{errors.otp}</span>}
                                    </div>
                                )}
                                <div className="text-center form-group mb-3">
                                    {showOtpInput ? (
                                        <button type="button" className="btn btn-primary " onClick={verifyOTP}>{t('Verify')}</button>
                                    ) : (
                                        <button type="submit" className="btn btn-primary" >{t('Update')}</button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Updateprofile;
