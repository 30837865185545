import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

import Swal from 'sweetalert2';

import { Link, } from "react-router-dom";
/// Scroll
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";

/// Image
import Setting from "./setting";
// import Calender from'../../components/AppsMenu/Calendar/Calendar'
import { ThemeContext } from "../../../context/ThemeContext";
import IntlDropdown from "../langdropdown";
import { getfamilyData, switchuseraccount, getprofile, getdataFlag, changeActivestatus } from "./store";
import { AesDecrypt } from "../../../services/crypto";


const AvatarPaths = {
  A: "",
  B: "",
  C: "",
  D: "",
  E: "",
  F: "",
  G: "",
  H: "",
  I: "",
  J: "",
  K: "",
  L: "",
  M: "",
  N: "",
  O: "",
  P: "",
  Q: "",
  R: "",
  S: "",
  T: "",
  U: "",
  V: "",
  W: "",
  X: "",
  Y: "",
  Z: ""
};
const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const history = useHistory();
  const [Id, setId] = useState("");
  const [Alldata, setAllHead] = useState("");
  const [userFlag, setuserFlag] = useState("");

  const handleReloadTable = () => {
    dispatch(getfamilyData(Id)).then((res) => {
      const head = AesDecrypt(res.payload.data)
      setAllHead(head)
    })
  };

  useEffect(() => {
    const id = localStorage.parentid;
    setId(id);
    if (Id) {
      dispatch(getfamilyData(Id)).then((res) => {
        const head = AesDecrypt(res.payload.data)
        setAllHead(head)
      })
    }
  }, [dispatch, Id])

  const getFlag = () => {

    const Id = localStorage.id;
    dispatch(getprofile(Id))
      .then((res) => {
        if (res.payload.response === 'S') {
          const data1 = AesDecrypt(res.payload.data);
          if (Array.isArray(data1) && data1.length !== 0) {
            setAvatarImg(data1[0].image);
          }
        }
      })

    dispatch(getdataFlag(Id)).then((response) => {
      const userdataFlag = response.payload
      setuserFlag(userdataFlag)
    })
    handleReloadTable()
  }


  const switchuser = (switchid) => {
    const currentid = localStorage.id;
    const data = { currentid, switchid };
    Swal.fire({
      title: t("Confirm Switch Account"),
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(changeActivestatus(data)).then((res) => {
          const demo = res;
        });
        dispatch(switchuseraccount(switchid)).then((response) => {
          const responseData = response.payload;
          const data = AesDecrypt(response.payload.encryptedData);
          if (responseData.status === "success") {
            localStorage.removeItem('id', 'name', 'dob', 'lang');
            localStorage.setItem('id', (data._id));
            localStorage.setItem('dob', (data.dob));
            localStorage.setItem('name', (data.name));
            if (data.parentId) {
              localStorage.setItem('isChild', true)
            } else {
              localStorage.setItem('isChild', false)
            }
            Swal.fire(
              t("Account Switch Successful"),
              "",
              "success"
            ).then(() => {
              window.history.replaceState(null, null, "/dashboard");

              // Navigate to the dashboard without adding a new history entry
              window.location.replace("/dashboard");

              // Reload the page to ensure the dashboard is fully loaded
              window.location.reload();
            });
          } else {
            Swal.fire(
              t("Failed to switch account"),
              "",
              "error"
            );
          }
        });
      }
    });
  };


  const [avatarImg, setAvatarImg] = useState(null);



  const renderAvatar = () => {
    if (!Alldata.img) {
      const firstLetter = localname ? localname.charAt(0).toUpperCase() : '';
      return firstLetter;
    }
    // If image data is available, return the image
    return `data:image/jpeg;base64,${Alldata.img}`;
  };

  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getprofile(_id))
      .then((res) => {
                        
        if (res.payload.response === 'S') {
          const data1 = AesDecrypt(res.payload.data);

          if (Array.isArray(data1) && data1.length !== 0) {
            setAvatarImg(data1[0].image);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);

  const { background, changeBackground } = useContext(ThemeContext);
  function ChangeMode() {
    if (background.value === "light") {
      changeBackground({ value: "dark", label: 'Dark' })
    } else {
      changeBackground({ value: "light", label: 'Light' })
    }
  }
  const localname = localStorage.name


  return (

    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
            </div>

            <ul className="navbar-nav header-right">
              <li className="nav-item dropdown notification_dropdown"
                onClick={ChangeMode}
              >
                <Link to={"#"} className={`nav-link bell dz-theme-mode ${background.value === "dark" ? 'active' : ''}`}
                >
                  <i id="icon-light" className="fas fa-sun" style={{ color: "yellow" }}></i>
                  <i id="icon-dark" className="fas fa-moon"></i>
                </Link>
              </li>
              <Setting />
              <IntlDropdown />
              {/* <Calender/> */}
              {localname !== "undefined" && localname !== '' ? (

                <Dropdown as="li" className="nav-item dropdown header-profile" onClick={() => getFlag(Id)}>
                  <Dropdown.Toggle
                    variant=""
                    className="nav-link i-false"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    {avatarImg && (
                      <img src={`data:image/jpeg;base64,${avatarImg}`} width={20} alt="" />
                    )}


                    {!avatarImg && (
                      <div className="avatar-circleheader avatar-letter1">{renderAvatar()}</div>

                    )}

                    <div className="header-info">
                      <span className="text-black">

                        <strong>{localname}</strong>

                      </span>
                    </div>
                  </Dropdown.Toggle>
                  {userFlag.userDataFlag === "A" && (
                    <Dropdown.Menu align="right" className="mt-2">
                      {Alldata && Alldata.map((data, index) => {
                        if (data._id !== localStorage.id) {
                          return (
                            <Link key={index} className="dropdown-item ai-icon" onClick={() => switchuser(data._id)}>
                              <svg
                                id="icon-user1"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-black"
                                width={18}
                                height={18}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                <circle cx={12} cy={7} r={4} />
                              </svg>
                              <span className="ms-2 text-black">{data.name}</span>
                            </Link>
                          );
                        } else {
                          return null; // If data._id is equal to localStorage ID, don't render this item
                        }
                      })}

                      <Link to="/app-profile" className="dropdown-item ai-icon">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          className="text-black"
                          viewBox="0 0 576 512"
                          width={18}
                          height={18}
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={40}
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          <path d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z" /></svg>

                        <span className="ms-2 text-black">{t('User Profile')}</span>
                      </Link>

                      {/* {UserType === "User" && (
                        <Link to="/changepassword" className="dropdown-item ai-icon">
                       <svg xmlns="http://www.w3.org/2000/svg"
                       id="icon-user1"
                        className="text-black"
                        viewBox="0 0 448 512"
                        width={18}
                        height={18}
                      
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={40}
                        strokeLinecap="round"
                        strokeLinejoin="round">
                          
                        <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
                        </svg>
                          <span className="ms-2 text-black">{t('Change Password')}</span>
                        </Link>
                      )} */}
                      {/* <LogoutPage />/ */}
                      {/* {Alldata && Alldata.map((member, index) => (
                        <div key={index}>
                          {member.activeStatus === 'A' && (
                            <Deleteuser />
                          )}
                        </div>
                      ))} */}

                    </Dropdown.Menu>
                  )}
                </Dropdown>
              ) : ("")}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
