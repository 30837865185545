import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, CardTitle, Input, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddMood } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';

const MoodMonitor = ({ onMoodSaved }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mood, setMood] = useState("");
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [activeSection, setActiveSection] = useState("mood");
  const { t } = useTranslation();
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  const handleMoodSelection = (selectedMood) => {
    setMood(selectedMood);
    setSelectedMood(selectedMood);
    setTimeout(() => {
      setActiveSection("reason");
    }, 500); // Delay of 2 seconds
  };

  const handleReasonSelection = (selectedReason) => {
    setReason(selectedReason);
    setSelectedReason(selectedReason);
    setTimeout(() => {
      setActiveSection("note");
    }, 500); // Delay of 2 seconds
  };

  const userId = localStorage.id;

  const handleSaveMood = () => {
    const MoodData = { mood, reason, note, userId };
    dispatch(AddMood(MoodData)).then((response) => {
      const decryptedMoodData = AesDecrypt(response.payload.data);
      if (response.payload.response === 'S') {
        navigate('/mood_monitor');
      }
    });
    setActiveSection("mood");
    setMood("");
    setReason("");
    setNote("");
    setSelectedMood("");
    setSelectedReason("");
  };

  const moodOptions = [
    { value: "Anger", label: t("Anger"), emoji: "😡" },
    { value: "Fear", label: t("Fear"), emoji: "😨" },
    { value: "Sadness", label: t("Sadness"), emoji: "😔" },
    { value: "Happy", label: t("Happy"), emoji: "😁" },
    { value: "Cheerful", label: t("Cheerful"), emoji: "💐" },
    { value: "Romantic", label: t("Romantic"), emoji: "🥰" },
    { value: "Madness", label: t("Madness"), emoji: "🤯" },
    { value: "Calm", label: t("Calm"), emoji: "😌" },
    { value: "Negative", label: t("Negative"), emoji: "🤬" },
    { value: "Anxious", label: t("Anxious"), emoji: "🥴" },
    { value: "Irritability", label: t("Irritability"), emoji: "😬" },
    { value: "Disgust", label: t("Disgust"), emoji: "🤢" }
  ];

  const reasonOptions = [
    { value: "Family", label: t("Family") },
    { value: "Friend", label: t("Friend") },
    { value: "School", label: t("School") },
    { value: "College", label: t("College") },
    { value: "Education", label: t("Education") },
    { value: "Love", label: t("Love") },
    { value: "Care", label: t("Care") },
    { value: "Others", label: t("Others") },
  ];

  return (
    <Fragment>
      <div>
        <div className=''>
          <Link to={"/mood_monitor"}>
            <i style={{ color: '#007bff', fontSize: '32px' }} className="fas fa-arrow-alt-circle-left"></i>
          </Link>
        </div>
      </div>
      <br />
      <h2>{t('Mood Monitor')}</h2>
      <Card>
        <CardBody>
          <CardTitle className="text-black" onClick={() => setActiveSection("mood")}>
            {t('Select your mood')}
            <i className={`fas fa-chevron-${activeSection === "mood" ? "up" : "down"}`} style={{ float: 'right' }}></i>
          </CardTitle>
          {activeSection === "mood" && (
            <div className="mood-grid">
              {moodOptions.map((option, index) => (
                <div key={index}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'white'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = selectedMood === option.value ? 'white' : 'white'}
                  className={`mood-item ${selectedMood === option.value ? "selected" : ""}`}
                >
                  <span
                    className={selectedMood === option.value ? "selected" : ""}
                    onClick={() => handleMoodSelection(option.value)}
                  >
                    {option.emoji}
                  </span>
                  <br></br>
                  <p onClick={() => handleMoodSelection(option.value)}>{option.label}</p>
                </div>
              ))}
            </div>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardTitle className="text-black" style={{ padding: '20px' }} onClick={() => selectedMood && setActiveSection("reason")}>
          {t('Reason For Your Mood')}
          <i className={`fas fa-chevron-${activeSection === "reason" ? "up" : "down"}`} style={{ float: 'right' }}></i>
        </CardTitle>
        <CardBody>
          {activeSection === "reason" && selectedMood && (
            <div className="mood-grid-reason">
              {reasonOptions.map((option, index) => (
                <div key={index} className="mood-item">
                  <p
                    className={selectedReason === option.value ? "selected" : ""}
                    onClick={() => handleReasonSelection(option.value)}
                  >
                    {option.label}
                  </p>
                </div>
              ))}
            </div>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardTitle style={{ padding: '20px' }} onClick={() => selectedReason && setActiveSection("note")}>
          {t('Add A Note')}
          <i className={`fas fa-chevron-${activeSection === "note" ? "up" : "down"}`} style={{ float: 'right' }}></i>
        </CardTitle>
        <CardBody>
          {activeSection === "note" && selectedReason && (
            <div className="mood-input">
              <textarea
                style={{ height: '200px', padding: '10px' }}
                placeholder=""
                className="important-height"
                onChange={(e) => setNote(e.target.value)}
              />
              <Button
                style={{ marginTop: '20px' }}
                color="primary"
                className="small-button"
                onClick={handleSaveMood}
              >
                {t('Save')}
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default MoodMonitor;