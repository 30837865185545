import React, {  } from "react";

import EventCalendar from "./EventCalendar";

const Calendar = () => {

   return (
      <div  className="h-80">
         <EventCalendar/>
      </div>
   );
};

export default Calendar;

