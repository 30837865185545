import React, { useEffect, useState } from 'react';
import MicrosoftTeamsImage from './Demographic-images/Birthday.png';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-flatpickr';
import Flatpickr from 'react-flatpickr'
const Dob = ({ handleNextClick, value, setvalue }) => {
  const [dob, setDOB] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    // Retrieve the DOB from localStorage and set it as initial value
    const storedDOB = localStorage.getItem('dob');
    if (storedDOB) {
      const dobDate = new Date(storedDOB);
      setDOB(dobDate);
    }
  }, []);

  const formatDate = (date) => {
    if (!date) return ''; // Return an empty string if the date is not provided
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    // return `${day}-${month}-${year}`;
    return `${year}-${month}-${day}`;
  };

  
  const handleSubmit = () => {
    if (dob instanceof Date && !isNaN(dob.getTime())) {
      const formattedDOB = formatDate(dob);
      localStorage.setItem('dob', formattedDOB);
      handleNextClick();
    } else {
      setvalue(2)
      setErrorMessage(t('Please select your date of birth'));
    }
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };


  useEffect(() => {
    if (value === 3) {
      handleSubmit();

    }
  });


  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 13);

  return (
    <>
      <div className='D_container'>

        <div className='D_column D_img_container'>
          <img style={{ height: "auto",width: "90%" }} className='Demograhy_image' src={MicrosoftTeamsImage} alt="Animated Birthday Image" />
        </div>


        <div className='D_column D_contant_container demography_card'>
          <div id='Demography_card'>
          {/* <div className='demography_main_contant'> */}

            <h3  style={{color:"black"}} >{t('Select your date of birth?')}</h3>
            <div>
              <input
                type='date'
                value={dob ? formatDate(dob) : ''} // Format date if dob exists
                onChange={(e) => setDOB(new Date(e.target.value))}
                onKeyDown={handleKeyDown}
                className="dob-input"
                placeholder='dd/mm/yyyy'
                max={formatDate(minDate)}
              />
              {/* <DesktopDatePicker></DesktopDatePicker> */}

              {errorMessage && <p className="error-message">{errorMessage}</p>}
            {/* </div> */}
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dob;
