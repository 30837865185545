import { Card, Row, Col, Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postDiabetesData } from "./store";
import { useDispatch } from "react-redux";

const DiabetesForm = () => {

    return (
        <div className="h-80">

        </div>
    );
};

export default DiabetesForm;
