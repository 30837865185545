import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { getlanguage, getvegdata } from "./store/index";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'
import BreadCrumbs from "../../breadcrumbs";

const Veg = () => {
  const [imageData, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        const lang = res.payload.language;
        dispatch(getvegdata(lang)).then((response) => {
          setImageData(response.payload.data);
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getvegdata()).then((response) => {
      setImageData(response.payload.data);
    });
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredVegetables = imageData.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <Fragment>
      <BreadCrumbs title={t('Vegetables')} data={[{ title: t('Vegetables') }]} />

      {/* <h2 className="mb-4">{t("Vegetables")}</h2>  */}

      <div className="shopping-cart mb-3">
        <input
          type="text"
          placeholder="Search Vegetable Name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control text-black"
        />
      </div>

      <div className="row">
        {filteredVegetables !== undefined && filteredVegetables.map((item, index) => (
          <div className="col-sm-3" key={index}>
            <div
              className="card card-zoom"
              style={{
                boxShadow: " 0px 0px 10px 0px rgb(153, 201, 144)",
              }}
            >
              <Link to={`/veg-details/${item._id}`}>
                <div className="card-body pb-3">
                  <div className="media mb-3">
                    <img
                      src={item.img}
                      alt=""
                      className="rounded me-3"
                      width={86}
                    />
                    <div className="media-body">
                      <div
                        className="fs-20 font-w600"
                        dangerouslySetInnerHTML={{ __html: t(item.name) }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Veg;
